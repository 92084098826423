import api from "../../../api";

export interface IAudioSourceRepository {
  getAudioSource: (
    fileId: string
  ) => Promise<{
    status: string;
    pps?: number;
    wave?: number[];
  }>;
}

class VideoSourceRepository implements IAudioSourceRepository {
  public getAudioSource = async (fileId: string) => {
    const { data } = await api.getAudioWaveForm(fileId);

    if (data.status !== "DONE") {
      return { status: data.status };
    }

    const file = await getFileFromUrl(data.waveFormDownloadUrl);

    return {
      status: "DONE",
      pps: file.sample_rate / file.samples_per_pixel,
      wave: file.data,
    };
  };
}

const getFileFromUrl = async (url: string) => {
  return fetch(url).then((res) => res.json());
};

export default VideoSourceRepository;
