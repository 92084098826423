import * as React from "react";
import { useContext, useRef } from "react";
import SideBarItem from "./SideBarItem";

import AddFolderImage from "../../../../assets/images/folder-add-24-px.svg";
import FileUploadImage from "../../../../assets/images/file-upload-24-px.svg";
// import FolderUploadImage from "../../../../assets/images/folder-upload-24-px.svg";
import SendFeedBackImage from "../../../../assets/images/feedback-24-px.svg";
import { useHistory, useLocation } from "react-router-dom";
import { PopupContext } from "../../../Contexts/PopupContext";
import CreateDirectoryPopup from "./CreateDirectoryPopup";
import { CreateFileManagerContext } from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";
import styled from "styled-components";
import { isMP4File } from "../../../../utils/utils";

export const defaultName = "제목없는 폴더";

const BottomSideBar = () => {
  const { showPopup } = useContext(PopupContext);
  const { createDirectory } = useContext(CreateFileManagerContext);
  const location = useLocation();
  const history = useHistory();
  const directoryId = location.pathname.split("/").pop();
  const isDirectoryId = location.pathname.split("/")[location.pathname.split("/").length - 2] === "directory";

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const callCreateDirectory = () => {
    if (showPopup && isDirectoryId && directoryId) {
      showPopup!(
        <CreateDirectoryPopup
          directoryId={directoryId}
          defaultName={defaultName}
          onCreateDirectory={onCreateDirectory}
        />
      );
    }
  };

  const onCreateDirectory = (name: string) => {
    if (isDirectoryId && directoryId != null) {
      createDirectory!(directoryId, name);
    }
  };

  const onFileUpload = () => {
    if (fileInputRef != null && fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const redirectToFeedback = () => {
    history.push("/feedback");
  };

  const sideBarItems = [
    {
      key: "",
      icon: AddFolderImage,
      text: <>새 폴더 추가</>,
      onClick: callCreateDirectory,
    },
    {
      key: "",
      icon: FileUploadImage,
      text: <>파일 업로드</>,
      onClick: onFileUpload,
    },
    // {
    //   key: "",
    //   icon: FolderUploadImage,
    //   text: <>폴더 업로드</>,
    //   onClick: onFileUpload,
    // },
    { key: "feedback", icon: SendFeedBackImage, text: <>피드백 보내기</>, onClick: redirectToFeedback },
  ];

  const sideBars = sideBarItems.map((item) => item);

  const { startVideoFileCreateProcessFromUpload } = useContext(CreateFileManagerContext);

  return (
    <>
      {sideBars.map(({ key, icon, text, onClick }, idx) => (
        <>
          {key === "feedback" && <Divider />}
          <SideBarItem
            toggled={key === "feedback" && location.pathname === "/feedback"}
            key={idx}
            icon={icon}
            text={text}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
          />
        </>
      ))}
      <input
        style={{ display: "none" }}
        type={"file"}
        ref={fileInputRef}
        multiple={true}
        onChange={async (e) => {
          const files = e.target.files!;

          if (files != null && directoryId != null) {
            for (let i = 0; i < files.length; i++) {
              if (!isMP4File(files[i].name)) {
                alert("mp4 파일만 업로드 가능합니다");
              } else {
                startVideoFileCreateProcessFromUpload!(directoryId, files[i], files[i].name);
              }
            }
          }
          e.target.value = "";
        }}
      />
    </>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdde3;
  margin-bottom: 5px;
`;

export default BottomSideBar;
