class Throttler {
  private timer: number | null;
  constructor() {
    this.timer = null;
  }

  public throttle = (f: () => void, thresholdTimeInMilliSeconds: number = 50) => {
    if (this.timer == null) {
      f();
      this.timer = window.setTimeout(() => {
        f();
        this.timer = null;
      }, thresholdTimeInMilliSeconds);
    }
  };
}

export default Throttler;
