import * as React from "react";
import * as EventListeners from "../../../eventListeners";
import { IDirectoryDTO, IFileDTO } from "../../../Models/Interfaces";
import { IDirectoryUseCase, IUploadHandlers } from "../../../UseCases/Interfaces";
import { DirectoryUseCases } from "../../../UseCases/Directory/Directory";
import VideoFileUseCase, { IFileCreateHandlers } from "../../../UseCases/Files/VideoFile/VideoFile";
import { IAnalysisHandler } from "../../../Drivers/Analysis";
import { IAudioExtractHandlers } from "../../../Drivers/AudioExtract";

export interface ICreateFileSubscribe {
  subscribePipelineStart?: (func: (params: IVideoFilePipelineProcess) => void) => { unsubscribe: () => void };

  subscribeFileUploading?: (
    func: (params: { processId: number; loaded: number; total: number }) => void
  ) => { unsubscribe: () => void };
  subscribeFileUploaded?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };
  subscribeFileUploadCanceled?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };

  subscribeFileCreated?: (
    func: (params: { directoryId: string; processId: number; file: IFileDTO }) => void
  ) => { unsubscribe: () => void };

  subscribeAudioExtracting?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };
  subscribeAudioExtracted?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };
  subscribeAudioExtractError?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };

  subscribeAudioWaveExtracting?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };
  subscribeAudioWaveExtracted?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };
  subscribeAudioWaveExtractError?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };

  subscribeFileAnalyzing?: (
    func: (params: { processId: number; progress: number }) => void
  ) => { unsubscribe: () => void };
  subscribeFileAnalyzed?: (
    func: (params: { processId: number; fileId: string }) => void
  ) => { unsubscribe: () => void };
  subscribeFileAnalyzeError?: (func: (params: { processId: number }) => void) => { unsubscribe: () => void };

  subscribeFileCopied?: (
    func: (params: { directoryId: string; file: IFileDTO }) => void
  ) => { unsubscribe: () => void };
}

export interface ICreateDirectorySubscribe {
  subscribeDirectoryCreated?: (
    func: (params: { directoryId: string; directory: IDirectoryDTO }) => void
  ) => { unsubscribe: () => void };
}

export const CreateFileManagerContext = React.createContext<
  {
    startVideoFileCreateProcessFromUpload?: (directoryId: string, file: File, name: string) => Promise<IFileDTO>;
    startVideoFileCreateProcessFromCreateFile?: (
      directoryId: string,
      s3Path: string,
      s3Url: string,
      name: string
    ) => Promise<IFileDTO>;
    copyFile?: (id: string, directoryId: string) => void;
    createDirectory?: (directoryId: string, name: string) => Promise<void>;
  } & ICreateFileSubscribe &
    ICreateDirectorySubscribe
>({});

export interface IVideoFilePipelineProcess {
  processId: number;
  name: string;
  cancelUpload?: () => void;
  uploadProgress?: number;
  file?: File;
  isUploading?: boolean;
  isUploaded?: boolean;
  isUploadError?: boolean;
  isAnalyzing?: boolean;
  isAnalyzed?: boolean;
  isAnalyzeError?: boolean;
  isAudioExtracting?: boolean;
  isAudioExtracted?: boolean;
  isAudioExtractError?: boolean;
  isAudioWaveExtracting?: boolean;
  isAudioWaveExtracted?: boolean;
  isAudioWaveExtractError?: boolean;
  analyzeHandlers?: IAnalysisHandler;
  audioExtractHandlers?: IAudioExtractHandlers;
  waveFormExtractHandlers?: IAudioExtractHandlers;
}

class CreateFileManagerContextProvider extends React.Component<{ isLogin?: boolean }> {
  private processId: number;
  private videoFileUseCase;
  private directoryUseCase: IDirectoryUseCase;

  constructor(props: { isLogin?: boolean }) {
    super(props);
    this.videoFileUseCase = new VideoFileUseCase();
    this.directoryUseCase = new DirectoryUseCases();
    this.processId = 0;
  }

  public async componentDidMount() {
    if (this.props.isLogin) {
      await this.requestProcessingFile();
    }
  }

  public async componentDidUpdate(prevProps: Readonly<{ isLogin: boolean }>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.isLogin !== this.props.isLogin && this.props.isLogin) {
      await this.requestProcessingFile();
    }
  }

  private requestProcessingFile = async () => {
    const files = await this.videoFileUseCase.getProcessingFiles();

    files.forEach((file) => {
      const pipeline = this.createPipeline({ name: file.name });
      const handlers: IFileCreateHandlers = {
        audioExtractHandlers: this.createAudioExtractNotificationHandlers(pipeline.processId),
        audioWaveExtractHandlers: this.createAudioWaveExtractNotificationHandlers(pipeline.processId),
        analyzeHandlers: this.createAnalysisNotificationHandlers(pipeline.processId),
      };

      this.notifyPipelineStart(pipeline);

      this.videoFileUseCase.checkAfterCreateProcess(file.id, handlers);
    });
  };

  private createPipeline = (pipelineInfo: Omit<IVideoFilePipelineProcess, "processId">) => {
    return {
      processId: this.processId++,
      ...pipelineInfo,
    };
  };

  public startVideoFileCreateProcessFromUpload = async (directoryId: string, file: File, name: string) => {
    const { convertFileNameIfDuplicated, addCreatingFileName, removeCreatingFileName } = this.videoFileUseCase;

    const fileName = await convertFileNameIfDuplicated(directoryId, name);
    addCreatingFileName(directoryId, fileName);

    const cancelUploadSubscribers: Array<() => void> = [];

    const cancelUpload = () => {
      removeCreatingFileName(directoryId, fileName);
      cancelUploadSubscribers.forEach((f) => f());
    };

    const addSubscribeCancelUpload = (func: () => void) => {
      cancelUploadSubscribers.push(func);
    };

    const pipeline = this.createPipeline({ file, name: fileName, cancelUpload });
    const uploadHandlers = this.createUploadNotificationHandlers(pipeline.processId, addSubscribeCancelUpload);
    const audioExtractHandlers = this.createAudioExtractNotificationHandlers(pipeline.processId);
    const audioWaveExtractHandlers = this.createAudioWaveExtractNotificationHandlers(pipeline.processId);

    const fileCreated = (createFile: IFileDTO) => {
      removeCreatingFileName(directoryId, fileName);
      this.notifyFileCreated(directoryId, createFile, pipeline.processId);
    };

    const analyzeHandlers = this.createAnalysisNotificationHandlers(pipeline.processId);

    const handlers: IFileCreateHandlers = {
      uploadHandlers,
      audioExtractHandlers,
      audioWaveExtractHandlers,
      analyzeHandlers,
      fileCreated,
    };

    this.notifyPipelineStart(pipeline);

    const fileDTO = await this.videoFileUseCase.createVideoFile({
      type: "UPLOAD",
      directoryId,
      file,
      name: fileName,
      handlers,
    });
    return fileDTO;
  };

  public startVideoFileCreateProcessFromCreateFile = async (
    directoryId: string,
    s3Path: string,
    s3Url: string,
    name: string
  ) => {
    const { convertFileNameIfDuplicated } = this.videoFileUseCase;
    const fileName = await convertFileNameIfDuplicated(directoryId, name);

    const pipeline = this.createPipeline({ name: fileName });

    const audioExtractHandlers = this.createAudioExtractNotificationHandlers(pipeline.processId);
    const audioWaveExtractHandlers = this.createAudioWaveExtractNotificationHandlers(pipeline.processId);
    const fileCreated = (createFile: IFileDTO) => {
      this.notifyFileCreated(directoryId, createFile, pipeline.processId);
    };

    const analyzeHandlers = this.createAnalysisNotificationHandlers(pipeline.processId);

    const handlers: IFileCreateHandlers = {
      audioExtractHandlers,
      audioWaveExtractHandlers,
      analyzeHandlers,
      fileCreated,
    };

    this.notifyPipelineStart(pipeline);

    const fileDTO = await this.videoFileUseCase.createVideoFile({
      type: "ALREADY_UPLOAD",
      directoryId,
      s3Path,
      s3Url,
      name: fileName,
      handlers,
    });
    return fileDTO;
  };

  private createUploadNotificationHandlers = (processId: number, addCancelSubscribers?: (f: () => void) => void) => {
    const handlers: IUploadHandlers = {
      onUpload: (loaded, total) => {
        this.notifyFileUploading(processId, loaded, total);
      },
      onUploaded: () => {
        this.notifyFileUploaded(processId);
      },
      onCancel: () => {
        this.notifyFileUploadCanceled(processId);
      },
      addCancelSubscribers,
    };
    return handlers;
  };

  private createAudioExtractNotificationHandlers = (processId: number) => {
    const handlers: IAudioExtractHandlers = {
      onProgress: () => {
        this.notifyAudioExtracting(processId);
      },
      onDone: () => {
        this.notifyAudioExtracted(processId);
      },
      onCanceled: () => {
        this.notifyAudioExtractError(processId);
      },
    };
    return handlers;
  };

  private createAudioWaveExtractNotificationHandlers = (processId: number) => {
    const handlers: IAudioExtractHandlers = {
      onProgress: () => {
        this.notifyAudioWaveExtracting(processId);
      },
      onDone: () => {
        this.notifyAudioWaveExtracted(processId);
      },
      onCanceled: () => {
        this.notifyAudioWaveExtractError(processId);
      },
    };
    return handlers;
  };

  private createAnalysisNotificationHandlers = (processId: number) => {
    const handlers: IAnalysisHandler = {
      onProgress: () => {
        this.notifyFileAnalyzing(processId, 0);
      },
      onDone: (fileId: string) => {
        this.notifyFileAnalyzed(processId, fileId);
      },
      onCanceled: () => {
        this.notifyFileAnalyzeError(processId);
      },
    };
    return handlers;
  };

  public copyFile = async (id: string, toDirectoryId: string) => {
    const file = await this.videoFileUseCase.copyFile(id, toDirectoryId);
    this.notifyFileCreated(toDirectoryId, file);
  };

  public createDirectory = async (directoryId: string, name: string) => {
    const file = await this.directoryUseCase.createDirectory(directoryId, name);
    this.notifyDirectoryCreated(directoryId, file);
  };

  public subscribePipelineStart = (func: (params: IVideoFilePipelineProcess) => void) => {
    EventListeners.addEventListeners("FILE_CREATE_PIPELINE_START", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_CREATE_PIPELINE_START", func),
    };
  };

  public subscribeFileUploading = (func: (params: { processId: number; loaded: number; total: number }) => void) => {
    EventListeners.addEventListeners("FILE_UPLOADING", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_UPLOADING", func),
    };
  };

  public subscribeFileUploaded = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("FILE_UPLOADED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_UPLOADED", func),
    };
  };

  public subscribeFileUploadCanceled = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("FILE_UPLOAD_CANCELED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_UPLOAD_CANCELED", func),
    };
  };

  public subscribeFileCreated = (
    func: (params: { directoryId: string; processId: number; file: IFileDTO }) => void
  ) => {
    EventListeners.addEventListeners("FILE_CREATED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_CREATED", func),
    };
  };

  public subscribeFileAnalyzing = (func: (params: { processId: number; progress: number }) => void) => {
    EventListeners.addEventListeners("FILE_ANALYZING", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_ANALYZING", func),
    };
  };

  public subscribeFileAnalyzed = (func: (params: { processId: number; fileId: string }) => void) => {
    EventListeners.addEventListeners("FILE_ANALYZED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_ANALYZED", func),
    };
  };

  public subscribeFileAnalyzeError = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("FILE_ANALYZE_ERROR", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_ANALYZE_ERROR", func),
    };
  };

  private notifyPipelineStart = (pipeline: IVideoFilePipelineProcess) => {
    EventListeners.emit("FILE_CREATE_PIPELINE_START", pipeline);
  };

  private notifyFileUploading = (processId: number, loaded: number, total: number) => {
    EventListeners.emit("FILE_UPLOADING", { processId, loaded, total });
  };

  private notifyFileUploaded = (processId: number) => {
    EventListeners.emit("FILE_UPLOADED", { processId });
  };

  private notifyFileUploadCanceled = (processId: number) => {
    EventListeners.emit("FILE_UPLOAD_CANCELED", { processId });
  };

  private notifyFileCreated = (directoryId: string, file: IFileDTO, processId?: number) => {
    EventListeners.emit("FILE_CREATED", { processId, directoryId, file });
  };

  private notifyFileAnalyzing = (processId: number, progress: number) => {
    EventListeners.emit("FILE_ANALYZING", { processId, progress });
  };

  private notifyFileAnalyzed = (processId: number, fileId: string) => {
    EventListeners.emit("FILE_ANALYZED", { processId, fileId });
  };

  private notifyFileAnalyzeError = (processId: number) => {
    EventListeners.emit("FILE_ANALYZE_ERROR", { processId });
  };

  public subscribeAudioExtracting = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("AUDIO_EXTRACTING", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("AUDIO_EXTRACTING", func),
    };
  };

  public subscribeAudioExtracted = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("AUDIO_EXTRACT_DONE", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("AUDIO_EXTRACT_DONE", func),
    };
  };

  public subscribeAudioWaveExtracting = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("AUDIO_WAVE_EXTRACTING", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("AUDIO_WAVE_EXTRACTING", func),
    };
  };

  private notifyAudioExtracting = (processId: number) => {
    EventListeners.emit("AUDIO_EXTRACTING", { processId });
  };

  private notifyAudioExtracted = (processId: number) => {
    EventListeners.emit("AUDIO_EXTRACT_DONE", { processId });
  };

  private notifyAudioExtractError = (processId: number) => {
    EventListeners.emit("AUDIO_EXTRACT_ERROR", { processId });
  };

  private notifyAudioWaveExtracting = (processId: number) => {
    EventListeners.emit("AUDIO_WAVE_EXTRACTING", { processId });
  };

  private notifyAudioWaveExtracted = (processId: number) => {
    EventListeners.emit("AUDIO_WAVE_EXTRACT_DONE", { processId });
  };

  private notifyAudioWaveExtractError = (processId: number) => {
    EventListeners.emit("AUDIO_WAVE_EXTRACT_ERROR", { processId });
  };

  public subscribeAudioWaveExtracted = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("AUDIO_WAVE_EXTRACT_DONE", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("AUDIO_WAVE_EXTRACT_DONE", func),
    };
  };

  public subscribeAudioWaveExtractError = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("AUDIO_WAVE_EXTRACT_ERROR", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("AUDIO_WAVE_EXTRACT_ERROR", func),
    };
  };

  public subscribeAudioExtractError = (func: (params: { processId: number }) => void) => {
    EventListeners.addEventListeners("AUDIO_EXTRACT_ERROR", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("AUDIO_EXTRACT_ERROR", func),
    };
  };

  public subscribeDirectoryCreated = (func: (params: { directoryId: string; directory: IDirectoryDTO }) => void) => {
    EventListeners.addEventListeners("DIRECTORY_CREATED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("DIRECTORY_CREATED", func),
    };
  };

  private notifyDirectoryCreated = (directoryId: string, directory: IDirectoryDTO) => {
    EventListeners.emit("DIRECTORY_CREATED", { directoryId, directory });
  };

  public subscribeFileCopied = (func: (params: { directoryId: string; file: IFileDTO }) => void) => {
    EventListeners.addEventListeners("FILE_COPIED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_COPIED", func),
    };
  };

  public render() {
    return (
      <CreateFileManagerContext.Provider
        value={{
          startVideoFileCreateProcessFromUpload: this.startVideoFileCreateProcessFromUpload,
          startVideoFileCreateProcessFromCreateFile: this.startVideoFileCreateProcessFromCreateFile,
          copyFile: this.copyFile,
          createDirectory: this.createDirectory,
          subscribePipelineStart: this.subscribePipelineStart,
          subscribeFileUploading: this.subscribeFileUploading,
          subscribeFileUploaded: this.subscribeFileUploaded,
          subscribeFileUploadCanceled: this.subscribeFileUploadCanceled,
          subscribeFileCreated: this.subscribeFileCreated,
          subscribeFileAnalyzing: this.subscribeFileAnalyzing,
          subscribeFileAnalyzed: this.subscribeFileAnalyzed,
          subscribeFileAnalyzeError: this.subscribeFileAnalyzeError,
          subscribeFileCopied: this.subscribeFileCopied,
          subscribeDirectoryCreated: this.subscribeDirectoryCreated,
          subscribeAudioExtracting: this.subscribeAudioExtracting,
          subscribeAudioExtracted: this.subscribeAudioExtracted,
          subscribeAudioExtractError: this.subscribeAudioExtractError,
          subscribeAudioWaveExtracting: this.subscribeAudioWaveExtracting,
          subscribeAudioWaveExtracted: this.subscribeAudioWaveExtracted,
          subscribeAudioWaveExtractError: this.subscribeAudioExtractError,
        }}
      >
        {this.props.children}
      </CreateFileManagerContext.Provider>
    );
  }
}

export default CreateFileManagerContextProvider;
