import api from "../api";

export interface IAudioDriver {
  checkExtractAudio: (fileId: string, handlers?: IAudioExtractHandlers) => Promise<void>;
  checkExtractWaveForm: (fileId: string, handlers?: IAudioExtractHandlers) => Promise<void>;
}

export interface IAudioExtractHandlers {
  onRequest?: () => void;
  onProgress?: () => void;
  onDone?: () => void;
  onCanceled?: () => void;
}

class AudioExtract implements IAudioDriver {
  public checkExtractAudio = async (fileId: string, handlers?: IAudioExtractHandlers) => {
    let isDone = false;
    let extractError: any; // error

    const checkStatus = () => {
      if (isDone) {
        if (handlers?.onDone) {
          handlers.onDone();
        }
        return;
      }

      if (extractError != null) {
        if (handlers?.onCanceled) {
          handlers.onCanceled();
        }
        return;
      }

      this.getExtractAudio(fileId)
        .then(({ status, waveFormS3Path, waveFormDownloadUrl }) => {
          if (status === "DONE") {
            isDone = true;
          }

          if (status === "ERROR") {
            extractError = "error";
          }

          if (status === "PROCESSING" || status === "WAITING" || status === "START") {
            if (handlers?.onProgress) {
              handlers.onProgress();
            }
          }

          setTimeout(checkStatus, 5000);
        })
        .catch((e) => {
          extractError = e;
          setTimeout(checkStatus, 5000);
        });
    };

    checkStatus();
  };

  private getExtractAudio = async (fileId: string) => {
    const { data } = await api.checkExtractAudio(fileId);
    return {
      status: data.status,
      waveFormS3Path: data.waveFormS3Path,
      waveFormDownloadUrl: data.waveFormDownloadUrl,
    };
  };

  public checkExtractWaveForm = async (fileId: string, handlers?: IAudioExtractHandlers) => {
    let isDone = false;
    let extractError: any; // error

    const checkStatus = () => {
      if (isDone) {
        if (handlers?.onDone) {
          handlers.onDone();
        }
        return;
      }

      if (extractError != null) {
        if (handlers?.onCanceled) {
          handlers.onCanceled();
        }
        return;
      }

      this.getExtractWaveForm(fileId)
        .then(({ status, waveFormS3Path, waveFormDownloadUrl }) => {
          if (status === "DONE") {
            isDone = true;
          }

          if (status === "ERROR") {
            extractError = "error";
          }

          if (status === "PROCESSING" || status === "WAITING" || status === "START") {
            if (handlers?.onProgress) {
              handlers.onProgress();
            }
          }
          setTimeout(checkStatus, 5000);
        })
        .catch((e) => {
          extractError = e;
          setTimeout(checkStatus, 5000);
        });
    };

    checkStatus();
  };

  public getExtractWaveForm = async (fileId: string) => {
    const { data } = await api.checkExtractWaveForm(fileId);
    return {
      status: data.status,
      waveFormS3Path: data.waveFormS3Path,
      waveFormDownloadUrl: data.waveFormDownloadUrl,
    };
  };
}

export default AudioExtract;
