import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ISelection } from "../../../../../Programs/VideoEditor/Controllers/VideoEditor";
import styled from "styled-components";
import TimelineHeader from "./TimelineHeader";
import TextTimeline from "./TextTimeline";
import AudioWaveTimeline from "./AudioWaveTimeline";
import SelectionBox from "./SelectionBox";
import { IAudioSource, ITextScript, ITextSource } from "../../../../../Programs/Interfaces";
import Throttler from "../../../../../utils/Throttle";
import { useElementSize } from "./AudioWave";

const Timeline = React.memo(
  (props: {
    textSource?: ITextSource;
    durationInMilliSeconds: number;
    focusScript?: ITextScript;
    selection: ISelection | null;
    select: (selection: ISelection | null) => void;
    audioSource?: IAudioSource;
    seek: (timeInMilliSeconds: number) => void;
    pause: () => void;
    subscribeTime: (f: (timeInMilliSeconds: number) => void) => { unsubscribe: () => void } | undefined;
  }) => {
    const { textSource, audioSource, durationInMilliSeconds, selection, select, seek, subscribeTime, pause } = props;
    const [unitWidth, setUnitWidth] = useState<number>(80);
    const parentWidth = (unitWidth * durationInMilliSeconds) / 1000;
    const { setScrollX, scrollContentRef, scrollRef, scrollX } = useScrollArea({ disabled: false });
    const { clientWidth: windowWidth } = useElementSize(scrollRef);

    const timeBarRef = useRef<HTMLDivElement | null>(null);

    const timeBarMove = useCallback(
      (timeInMilliSeconds: number) => {
        if (timeBarRef.current && scrollRef.current) {
          const left = parentWidth * (timeInMilliSeconds / durationInMilliSeconds);
          timeBarRef.current.style.left = `${left}px`;
        }
      },
      [durationInMilliSeconds, parentWidth, scrollRef]
    );

    useEffect(() => {
      const subscription = subscribeTime(timeBarMove);
      return subscription?.unsubscribe;
    }, [timeBarMove, timeBarRef, subscribeTime]);

    const focusToCenter = useCallback(
      (timeInMillSeconds: number) => {
        if (windowWidth) {
          setScrollX((parentWidth * timeInMillSeconds) / durationInMilliSeconds - windowWidth / 2);
        }
      },
      [durationInMilliSeconds, parentWidth, setScrollX, windowWidth]
    );

    useEffect(() => {
      const subscription = subscribeTime(focusToCenter);
      return subscription?.unsubscribe;
    }, [focusToCenter, subscribeTime]);

    const throttler = useMemo(() => new Throttler(), []);
    const onWheel = useCallback(
      (e: React.WheelEvent) => {
        throttler.throttle(() => {
          setUnitWidth((prev) => {
            const delta = e.deltaY > 0 ? 10 : -10;
            if (prev > 160 && delta > 0) {
              return prev;
            }
            if (prev < 40 && delta < 0) {
              return prev;
            }

            return prev + delta;
          });
        }, 60);
      },
      [throttler]
    );

    if (parentWidth == null) {
      return null;
    }

    return (
      <TimelineWrapper selectMode={selection != null}>
        <StyledTimeline ref={scrollRef}>
          <Alignment
            ref={scrollContentRef}
            style={{ width: parentWidth, transform: `translateX(${-scrollX}px)` }}
            onWheel={onWheel}
          >
            <TimelineHeader
              unitWidth={unitWidth}
              parentWidth={parentWidth}
              durationInMilliSeconds={durationInMilliSeconds}
              scrollX={scrollX}
              windowWidth={windowWidth || 0}
            />
            <SourceWrapper>
              <TextTimeline
                selection={selection}
                duration={durationInMilliSeconds}
                parentWidth={parentWidth}
                textSource={textSource}
                subscribeTime={subscribeTime}
                height={110}
                unitWidth={unitWidth}
                scrollX={scrollX}
                windowWidth={windowWidth || 0}
              />
              {audioSource?.data && (
                <AudioWaveTimeline
                  selectionMode={selection != null}
                  durationInMilliSeconds={durationInMilliSeconds}
                  wave={audioSource?.data.wave}
                  pps={audioSource.data.pps}
                  seek={seek}
                  unitWidth={unitWidth}
                  height={80}
                  scrollX={scrollX}
                />
              )}
              {selection && (
                <SelectionBoxWrapper>
                  <SelectionBox
                    pause={pause}
                    durationInMilliSeconds={durationInMilliSeconds}
                    parentWidth={parentWidth}
                    selection={selection}
                    select={select}
                  />
                </SelectionBoxWrapper>
              )}
              <StyledTimeBar ref={timeBarRef} />
            </SourceWrapper>
          </Alignment>
        </StyledTimeline>
      </TimelineWrapper>
    );
  }
);

interface IOptions {
  disabled: boolean;
}

function useScrollArea(options: IOptions) {
  const { disabled } = options;
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const scrollContentRef = React.useRef<HTMLDivElement>(null);

  const [scrollX, setScrollX] = React.useState(0);
  const setSafeScrollX = React.useCallback((arg: number | ((prev: number) => number)) => {
    setScrollX((prev) => {
      if (!scrollRef.current || !scrollContentRef.current) {
        return prev;
      }

      const next = typeof arg === "function" ? arg(prev) : arg;

      if (next <= 0) {
        return 0;
      }

      const max = scrollContentRef.current.clientWidth - scrollRef.current.clientWidth;
      return Math.min(next, max);
    });
  }, []);

  React.useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement || disabled) {
      return;
    }

    const onWheel = (e: WheelEvent) => {
      setSafeScrollX((prev) => prev + e.deltaX);
      e.preventDefault();
    };

    scrollElement.addEventListener("wheel", onWheel);
    return () => scrollElement.removeEventListener("wheel", onWheel);
  }, [disabled, setSafeScrollX]);

  return React.useMemo(
    () => ({
      scrollRef,
      scrollContentRef,
      scrollX,
      setScrollX: setSafeScrollX,
    }),
    [scrollX, setSafeScrollX]
  );
}

export default Timeline;

const SelectionBoxWrapper = styled.div`
  height: 100%;
`;

const StyledTimeBar = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0;
  width: 2px;
  background-color: #ff4444;
  border-top: 5px solid #ff4444;
  border-bottom: 5px solid #ff4444;
  margin-bottom: -5px;
`;

const SourceWrapper = styled.div`
  position: relative;
  padding-top: 29px;
`;

const Alignment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
`;

const StyledTimeline = styled.div`
  position: relative;
  width: 100%;
  overflow: scroll;

  ${SelectionBoxWrapper} {
    position: absolute;
    bottom: 0;
  }
`;

const TimelineWrapper = styled.div<{ selectMode: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 29px 20px 21px 20px;
  box-sizing: border-box;
  ${(props) => (props.selectMode ? "border-radius: 3px;background-color: #f7f7f7;" : "")};
`;
