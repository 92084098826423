import api from "../api";

export interface IAnalysisHandler {
  onProgress?: () => void;
  onDone?: (fileId: string) => void;
  onCanceled?: () => void;
}

export interface IAnalysisDriver {
  checkAnalysis: (target: string, handlers?: IAnalysisHandler) => Promise<void>;
}

class AnalysisDriver implements IAnalysisDriver {
  public checkAnalysis = async (fileId: string, handlers?: IAnalysisHandler) => {
    let isDone = false;
    let analysisError: any; // error

    const checkStatus = () => {
      if (isDone) {
        if (handlers?.onDone) {
          handlers.onDone(fileId);
        }
        return;
      }

      if (analysisError != null) {
        if (handlers?.onCanceled) {
          handlers.onCanceled();
        }
      }

      this.getAnalysis(fileId)
        .then(({ done, progress, error }) => {
          if (done) {
            isDone = true;
          }

          if (error) {
            analysisError = error;
          }

          if (!done) {
            if (handlers?.onProgress) {
              handlers.onProgress();
            }
          }

          setTimeout(() => {
            checkStatus();
          }, 5000);
        })
        .catch((e) => {
          analysisError = e;
          setTimeout(() => {
            checkStatus();
          }, 5000);
        });
    };
    checkStatus();
  };

  private getAnalysis = async (fileId: string) => {
    const { data } = await api.checkAnalysis(fileId);
    return {
      done: data.done,
      progress: data.progress,
      error: data.error,
    };
  };
}

export default AnalysisDriver;
