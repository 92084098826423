import { IDirectoryUseCase } from "../Interfaces";
import { IDirectoryRepository } from "../../Respositories/Interfaces";
import DirectoryRepository from "../../Respositories/Directory";
import { DirectoryModel } from "../../Models/FileNode";
import { IDirectory } from "../../Models/Interfaces";
import api from "../../api";

export class DirectoryUseCases implements IDirectoryUseCase {
  private repository: IDirectoryRepository;

  constructor() {
    this.repository = new DirectoryRepository();
  }

  public getRootDirectory = async () => {
    const directory = await this.repository.getRootDirectory();
    return new DirectoryModel(directory).toDTO();
  };

  public getDirectory = async (id: string) => {
    const directory = await this.repository.getDirectory(id);
    return new DirectoryModel(directory).toDTO();
  };

  public createDirectory = async (parentDirectoryId: string, name: string) => {
    const directoryName = await this.convertDirectoryNameIfDuplicated(parentDirectoryId, name);
    const model: IDirectory = {
      parentDirectoryId,
      name: directoryName,
    };
    const directory = await this.repository.createDirectory(model);
    return new DirectoryModel(directory).toDTO();
  };

  public deleteDirectory = async (id: string) => {
    const directory = await this.repository.deleteDirectory(id);
    return new DirectoryModel(directory).toDTO();
  };

  public renameDirectory = async (id: string, name: string) => {
    const directory = await this.repository.renameDirectory(id, name);
    return new DirectoryModel(directory).toDTO();
  };

  public updateParentDirectory = async (id: string, directoryId: string) => {
    const { data } = await api.patchParentDirectory(id, directoryId);
    return data;
  };

  public askDirectoryFullPath = async (id: string): Promise<string[]> => {
    const path = await this.repository.askDirectoryFullPath(id);
    return path;
  };

  public convertDirectoryNameIfDuplicated = async (directoryId: string, name: string) => {
    const directory = await this.repository.getDirectory(directoryId);
    const subDirectories = directory.directories;

    if (subDirectories == null) {
      throw new Error("");
    }

    let counter: number | undefined;

    while (true) {
      if (counter == null) {
        const targets = subDirectories.filter((f) => f.name === name);
        if (targets.length === 0) {
          return name;
        }

        counter = 1;
      }

      const targetName = name + ` (${counter})`;
      const targets = subDirectories.filter((f) => f.name === targetName);

      if (targets.length > 0) {
        counter += 1;
        continue;
      }

      return targetName;
    }
  };
}
