import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";
import styled from "styled-components";

import DecreasePlayRateIcon from "../../../../../assets/images/fast-rewind-24-px.svg";
import IncreasePlayRateIcon from "../../../../../assets/images/fast-rewind-24-px-copy.svg";
import VolumeIcon from "../../../../../assets/images/sound.svg";

const PlayControlComponent = (props: { playerManager: PlayerManager }) => {
  const { playerManager } = props;
  const [playRate, setPlayRate] = useState(1);
  const [volume, setVolume] = useState(playerManager.getVolume());

  useEffect(() => {
    const currentPlayRate = playerManager.getPlaybackRate();
    setPlayRate(currentPlayRate);
  }, [playerManager]);

  const increasePlayRate = useCallback(() => {
    if (playerManager.getPlaybackRate() >= 2) {
      return;
    }
    const playRate = playerManager.increasePlaybackRate();
    setPlayRate(playRate);
  }, [playerManager]);

  const decreasePlayRate = useCallback(() => {
    if (playerManager.getPlaybackRate() <= 0.5) {
      return;
    }
    const playRate = playerManager.decreasePlaybackRate();
    setPlayRate(playRate);
  }, [playerManager]);

  const updateVolume = useCallback(
    (value: number) => {
      playerManager.updateVolume(value);
      setVolume(value);
    },
    [playerManager]
  );

  return (
    <StyledPlayControl>
      <PlayControlTitle>배속재생</PlayControlTitle>
      <PlayControlWrapper>
        <DecreasePlayRate src={DecreasePlayRateIcon} onClick={decreasePlayRate} />
        <CurrentPlayRate>{playRate}배</CurrentPlayRate>
        <IncreasePlayRate src={IncreasePlayRateIcon} onClick={increasePlayRate} />
      </PlayControlWrapper>
      <VolumeController volume={volume} updateVolume={updateVolume} />
    </StyledPlayControl>
  );
};

const VolumeController = (props: { volume: number; updateVolume: (value: number) => void }) => {
  const { volume, updateVolume } = props;
  const [isRangeShow, setIsRangeShow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const toggleIsRangeShow = useCallback(() => {
    setIsRangeShow(!isRangeShow);
  }, [isRangeShow]);

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
        setIsRangeShow(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    window.addEventListener("click", clickOutside);
    return () => {
      window.removeEventListener("click", clickOutside);
    };
  }, [clickOutside]);

  return (
    <StyledVolumeController onClick={toggleIsRangeShow} ref={ref}>
      <StyledVolumeIcon />
      {isRangeShow && <VolumeRange volume={volume} updateVolume={updateVolume} onClick={(e) => e.stopPropagation()} />}
    </StyledVolumeController>
  );
};

const VolumeRange = (props: {
  volume: number;
  updateVolume: (value: number) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}) => {
  const { volume, updateVolume, onClick } = props;
  return (
    <StyledVolumeRange
      value={volume}
      min={0}
      max={1}
      step={0.1}
      onClick={onClick}
      onChange={(e) => {
        updateVolume(Number(e.currentTarget.value));
      }}
    />
  );
};

const StyledVolumeIcon = styled.img.attrs({ src: VolumeIcon })``;

const StyledVolumeRange = styled.input.attrs({ type: "range" })``;

const StyledVolumeController = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${StyledVolumeIcon} {
    height: 16px;
    width: 16px;
  }

  ${StyledVolumeRange} {
    position: absolute;
    bottom: 35px;
    width: 50px;
    transform: rotate(270deg);
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const PlayControlTitle = styled.div``;

const DecreasePlayRate = styled.img`
  width: 24px;
  height: 24px;
`;
const IncreasePlayRate = styled.img`
  width: 24px;
  height: 24px;
`;
const CurrentPlayRate = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${DecreasePlayRate} {
    margin-right: 9px;
  }
  ${CurrentPlayRate} {
    margin-right: 9px;
  }
`;

const StyledPlayControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${PlayControlTitle} {
    margin-right: 23px;
  }

  ${StyledVolumeController} {
    margin-left: 40px;
  }
`;

export default PlayControlComponent;
