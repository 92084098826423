import * as React from "react";
import styled from "styled-components";
import WaveV3Component from "./AudioWave";

const AudioWaveTimeline = React.memo(
  (props: {
    durationInMilliSeconds: number;
    selectionMode: boolean;
    wave: number[];
    pps: number;
    height: number;
    unitWidth: number;
    scrollX: number;
    seek: (timeInMilliSeconds: number) => void;
  }) => {
    const { wave, pps, durationInMilliSeconds, unitWidth, height, seek, scrollX, selectionMode } = props;

    return (
      <StyledAudioTimeline height={height} selectionMode={selectionMode}>
        <AudioTimelineBox focused={false} id={"wave-container"}>
          <WaveV3Component
            duration={durationInMilliSeconds / 1000}
            wave={wave}
            pps={pps}
            onSeek={seek}
            pxPerSecond={unitWidth}
            scrollX={scrollX}
          />
        </AudioTimelineBox>
      </StyledAudioTimeline>
    );
  }
);

export default AudioWaveTimeline;

const AudioTimelineBox = styled.div<{ focused: boolean }>`
  position: absolute;
  width: 100%;
  height: 64px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
`;

const StyledAudioTimeline = styled.div<{ height: number; selectionMode: boolean }>`
  position: relative;
  height: ${(props) => props.height}px;
  padding: 12px 0 4px;
  border-radius: 3px;
  border: ${(props) => (props.selectionMode ? "1px solid #e5e6ec" : "1px solid #edeef4")};
  background-color: ${(props) => (props.selectionMode ? "#eeeeef" : "#f7f7f7")};
`;
