import * as React from "react";
import { ReactElement } from "react";
import "./App.css";
import ContextWrapper from "./Frameworks/Components/ContextWrapper";
import api from "./api";
import GoogleAnalyticsManager from "./Frameworks/Components/GoogleAnalyticsManager";

GoogleAnalyticsManager.initialize("UA-125354628-18");

const App = (): ReactElement | null => {
  return (
    <>
      <ContextWrapper />
    </>
  );
};

api.hello();

export default App;
