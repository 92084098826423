import SplitVideoUseCase, { ISplitVideoHandlers, ISplitVideoUseCase } from "../Usecases/SplitVideo";
import { IAudioSource, ITextSource, IVideoSource, Source } from "../../Interfaces";
import VideoSourceUseCase, { IVideoSourceUseCase } from "../Usecases/VideoSource";
import TextSourceUseCase, { ITextSourceUseCase } from "../Usecases/TextSource";
import AudioSourceUseCase, { IAudioSourceUseCase } from "../Usecases/AudioSource";

export interface IResult {
  duration: number;
  name: string;
  resultSources: {
    videoResults?: IVideoSource[];
    subtitleResults?: ITextSource[];
    audioResults?: IAudioSource[];
  };
}

export interface IProject {
  sources: Source[];
  result?: IResult;
}

export interface IVideoEditor {
  createNewProject: () => void;
  openProject: (project: IProject, range?: ISelection) => void;
  importAudioSource: (fileId: string) => Promise<IAudioSource | null>;
  importVideoSource: (fileId: string) => Promise<IVideoSource>;
  importTextSource: (fileId: string) => Promise<ITextSource | null>;
  splitVideo: (fileId: string, selection: ISelection) => Promise<IVideoSource>;
  subscribeEditorChanges: (func: (project: IProject) => void) => void;
}

export interface ISelection {
  startTimeInMillSeconds: number;
  endTimeInMillSeconds: number;
}

class VideoEditor implements IVideoEditor {
  private project?: IProject;
  private selection: ISelection | null;
  private subscribers: Array<(project: IProject) => void>;
  private splitVideoUsecase: ISplitVideoUseCase;
  private videoSourceUseCase: IVideoSourceUseCase;
  private audioSourceUseCase: IAudioSourceUseCase;
  private textSourceUseCase: ITextSourceUseCase;

  constructor() {
    this.subscribers = [];
    this.selection = null;
    this.splitVideoUsecase = new SplitVideoUseCase();
    this.audioSourceUseCase = new AudioSourceUseCase();
    this.textSourceUseCase = new TextSourceUseCase();
    this.videoSourceUseCase = new VideoSourceUseCase();
  }

  public createNewProject = () => {
    this.project = { sources: [] };
    this.onChange();
  };

  public openProject = (project: IProject) => {
    this.project = project;
    this.onChange();
  };

  public importVideoSource = async (fileId: string) => {
    const videoSource = await this.videoSourceUseCase.getVideoSource(fileId);
    this.importSource(videoSource);
    return videoSource;
  };

  public importAudioSource = async (fileId: string) => {
    const audioSource = await this.audioSourceUseCase.getAudioSource(fileId);
    if (audioSource == null) {
      return null;
    }

    this.importSource(audioSource);
    return audioSource;
  };

  public importTextSource = async (fileId: string) => {
    const textSource = await this.textSourceUseCase.getTextSource("", fileId);
    if (textSource == null) {
      return null;
    }

    this.importSource(textSource);
    return textSource;
  };

  private importSource = (source: Source) => {
    this.project = { ...this.project, sources: this.project?.sources ? [...this.project?.sources, source] : [source] };
    this.onChange();
  };

  public splitVideo = async (
    fileId: string,
    selection: ISelection,
    handlers?: ISplitVideoHandlers
  ): Promise<IVideoSource> => {
    const response = await this.splitVideoUsecase.requestSplit(fileId, selection, handlers);
    return {
      key: "",
      type: "VIDEO",
      data: {
        fileId,
        name: response.outputS3FilePathParams[0].fileName,
        src: response.outputDefinition[0].outputFileS3Url,
        url: response.outputS3FilePathParams[0].s3Path,
      },
    };
  };
  //
  // private getPartialSource = async (source: Source, selection: ISelection) => {
  //   if (source.type === "VIDEO" || source.type === "AUDIO") {
  //     return { ...source };
  //   }
  //
  //   const isInSelection = (s: {
  //     startTimeInMillSeconds: number;
  //     endTimeInMillSeconds: number;
  //   }) => {
  //     return true;
  //   };
  //
  //   const scripts = source.data.scripts;
  //
  //   return {
  //     ...source,
  //     data: {
  //       ...source.data,
  //       scripts: scripts
  //         ? // @ts-ignore
  //           scripts.filter((s: Script) => isInSelection(s))
  //         : [],
  //     },
  //   };
  // };

  private onChange = () => {
    if (this.project == null) {
      throw new Error("not initialized");
    }

    this.subscribers.forEach((func) => {
      func(this.project!);
    });
  };

  public subscribeEditorChanges = (func: (project: IProject) => void) => {
    this.subscribers.push(func);
    return {
      unsubscribe: () => {
        this.unsubscribeEditorChanges(func);
      },
    };
  };

  private unsubscribeEditorChanges = (func: (project: IProject) => void) => {
    this.subscribers = this.subscribers.filter((f) => f !== func);
  };
}

export default VideoEditor;
