import * as React from "react";
import { useState } from "react";
import { Description, Header, PageBody, PageHeader, PageWrapper } from "../Commons";
import styled from "styled-components";
import { Route, Switch, useHistory } from "react-router-dom";
import api from "../../../../api";

const Feedback = () => {
  return (
    <Switch>
      <Route path={"/feedback/done"} component={FeedBackDone} />
      <Route exact={true} path={"/feedback"} component={FeedBackWrite} />
    </Switch>
  );
};

const FeedBackWrite = () => {
  const history = useHistory();
  const [form, setForm] = useState("");

  const onCancel = () => {
    history.goBack();
  };

  const onConfirm = () => {
    api.sendFeedback(form).then(() => {
      history.push("/feedback/done");
    });
  };

  const placeHolder = `ex. ______ 하는 중 에러가 발생했어요.

ex. ______ 한 점이 불편하네요.

ex. ______ 는 이해가 잘 안돼요.

ex. ______ 의 목적으로 사용하기에는 유용하지 않아요.

ex. ______ 부분 생긴 게 안 마음에 안 들어요.

ex. ______ 할 수 있는 기능이 포함되면 좋겠군요.

ex. ______ 기능은 필요 없어요.`;

  return (
    <PageWrapper>
      <PageHeader>
        <Header>피드백 보내기</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <Description>
            VWS를 이용해 주셔서 감사합니다.
            <br /> 이용 중 불편하신 사항이 있으셨다면 아낌없이 피드백 주세요.
          </Description>
          <FormTextArea
            placeholder={placeHolder}
            value={form}
            onChange={(e) => {
              setForm(e.currentTarget.value);
            }}
          />
          <ButtonsWrapper>
            <CancelButton onClick={onCancel}>취소</CancelButton>
            <SendButton onClick={onConfirm}>보내기</SendButton>
          </ButtonsWrapper>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};
const FeedBackDone = () => {
  return (
    <PageWrapper>
      <PageHeader>
        <Header>피드백 보내기</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <FeedbackDoneText>
            더 강력한 VWS로 진화하겠습니다.
            <br /> 감사합니다.
          </FeedbackDoneText>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};

export default Feedback;

const FormTextArea = styled.textarea`
  width: 410px;
  height: 266px;
  padding: 15px 18px 62px;
  border-radius: 8px;
  background-color: #f7f8f9;
  resize: none;
  border: none;
  overflow-y: scroll;
  outline: none;
`;

const SendButton = styled.button`
  outline: none;
  border: none;
  width: 136px;
  height: 48px;
  padding: 14px 16px 13px 16px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #657eff;
`;

const CancelButton = styled.button`
  outline: none;
  border: none;
  width: 136px;
  height: 48px;
  padding: 14px 16px 13px 16px;
  border-radius: 12px;
  background-color: #f2f4f6;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${CancelButton} {
    margin-right: 7px;
  }
`;

const FormWrapper = styled.div`
  ${Description} {
    margin-bottom: 24px;
  }
  ${FormTextArea} {
    margin-bottom: 30px;
  }
`;

const FeedbackDoneText = styled.div`
  font-family: NanumSquareEB;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #33343d;
`;
