import { IFileUseCase } from "../Interfaces";
import { IDirectoryRepository, IFileRepository } from "../../Respositories/Interfaces";
import FileRepository from "../../Respositories/File";
import { IFile } from "../../Models/Interfaces";
import { FileModel } from "../../Models/FileNode";
import DirectoryRepository from "../../Respositories/Directory";

const creatingFilesName = new Map<string, string[]>();

const getCreatingFilesName = (directoryId: string) => {
  return creatingFilesName.get(directoryId) || [];
};

const addCreatingFilesName = (directoryId: string, name: string) => {
  const prevCreatingFiles = creatingFilesName.get(directoryId);
  creatingFilesName.set(directoryId, prevCreatingFiles != null ? [...prevCreatingFiles, name] : [name]);
};

const removeCreatingFilesName = (directoryId: string, name: string) => {
  const prevCreatingFiles = creatingFilesName.get(directoryId);

  if (prevCreatingFiles) {
    creatingFilesName.set(
      directoryId,
      prevCreatingFiles.filter((f) => f !== name)
    );
  }
};

export class FileUseCases implements IFileUseCase {
  protected repository: IFileRepository;
  private directoryRepository: IDirectoryRepository;

  constructor() {
    this.repository = new FileRepository();
    this.directoryRepository = new DirectoryRepository();
  }

  public createFile = async (directoryId: string, name: string, s3Path: string, s3Url: string) => {
    const fileModel: IFile = {
      directoryId,
      name,
      s3Path,
      s3Url,
    };

    const createdFile = await this.repository.createFile(directoryId, fileModel);
    return new FileModel(createdFile).toDTO();
  };

  public getFile = async (id: string) => {
    const file = await this.repository.getFile(id);
    return new FileModel(file).toDTO();
  };

  public deleteFile = async (id: string) => {
    const deletedFile = await this.repository.deleteFile(id);
    return new FileModel(deletedFile).toDTO();
  };

  public renameFile = async (id: string, name: string) => {
    const renamedFile = await this.repository.renameFile(id, name);
    return new FileModel(renamedFile).toDTO();
  };

  public updateParentDirectory = async (targetId: string, directoryId: string) => {
    const movedFile = await this.repository.updateParentDirectory(targetId, directoryId);
    return new FileModel(movedFile).toDTO();
  };

  public copyFile = async (id: string, directoryId: string) => {
    const movedFile = await this.repository.copyFile(id, directoryId);
    return new FileModel(movedFile).toDTO();
  };

  public convertFileNameIfDuplicated = async (directoryId: string, name: string) => {
    const directory = await this.directoryRepository.getDirectory(directoryId);
    const files = directory.files;
    const creatingFilesName = getCreatingFilesName(directoryId);

    if (files == null) {
      throw new Error("");
    }

    let counter: number | undefined;

    while (true) {
      if (counter == null) {
        const targets = [...files.map((f) => f.name), ...creatingFilesName].filter((fileName) => fileName === name);
        if (targets.length === 0) {
          return name;
        }

        counter = 1;
      }

      const nameArr = name.split(".");
      nameArr[nameArr.length - 2] = nameArr[nameArr.length - 2] + ` (${counter})`;

      const targetName = nameArr.join(".");

      const targets = [...files.map((f) => f.name), ...creatingFilesName].filter((fileName) => fileName === targetName);

      if (targets.length > 0) {
        counter += 1;
        continue;
      }

      return targetName;
    }
  };

  public addCreatingFileName = (directoryId: string, name: string) => {
    addCreatingFilesName(directoryId, name);
  };

  public removeCreatingFileName = (directoryId: string, name: string) => {
    removeCreatingFilesName(directoryId, name);
  };
}
