import * as React from "react";
import { useMemo, useRef, useState } from "react";
import styled from "styled-components";

const toTimeFormattedText = (timeInSeconds: number) => {
  const stringMinute: string = String(timeInSeconds / 60);
  const minute: number = parseInt(stringMinute, 10);
  const seconds: number = timeInSeconds - minute * 60;
  let rt = "";
  if (minute < 10) {
    rt += `0${minute.toFixed(0)}`;
  } else {
    rt += `${minute.toFixed(0)}`;
  }

  rt += ":";
  if (seconds < 10) {
    rt += `0${seconds.toFixed(0)}`;
  } else {
    rt += `${seconds.toFixed(0)}`;
  }

  return rt;
};

const TimelineHeader = React.memo(
  (props: {
    unitWidth: number;
    windowWidth: number;
    parentWidth: number;
    durationInMilliSeconds: number;
    scrollX: number;
  }) => {
    const { unitWidth, durationInMilliSeconds, scrollX, windowWidth } = props;
    const [initialUnitWidth] = useState(unitWidth);
    const textWidth = useMemo(() => initialUnitWidth - 30, [initialUnitWidth]);

    const timeArray = useMemo(() => {
      const timeArray = [];
      for (let i = 0; i < Math.ceil(durationInMilliSeconds / 1000); i++) {
        timeArray.push(i);
      }
      return timeArray;
    }, [durationInMilliSeconds]);

    const [startIdx, endIdx] = React.useMemo(() => {
      const start = Math.floor(scrollX / unitWidth);
      const end = Math.ceil((scrollX + windowWidth) / unitWidth);

      return [start, end];
    }, [scrollX, unitWidth, windowWidth]);

    const scale = useMemo(() => initialUnitWidth / unitWidth, [initialUnitWidth, unitWidth]);

    return (
      <StyledTimelineHeader>
        {timeArray
          .slice(startIdx, endIdx + 1)
          .filter((value) => value % Math.ceil(scale) === 0)
          .map((time, index) => (
            <TimeScaleComponent
              key={time + "_" + index}
              textWidth={textWidth}
              unitWidth={unitWidth}
              index={index}
              time={time}
            />
          ))}
      </StyledTimelineHeader>
    );
  }
);

const TimeScaleComponent = React.memo(
  (props: { index: number; unitWidth: number; textWidth: number; time: number }) => {
    const { textWidth, unitWidth, time } = props;
    const textWrapperRef = useRef<HTMLDivElement | null>(null);
    const textRef = useRef<HTMLDivElement | null>(null);
    const scaleLineRef = useRef<HTMLDivElement | null>(null);

    return (
      <TimeScaleWrapper
        width={unitWidth}
        textWidth={textWidth}
        ref={textWrapperRef}
        style={{ position: "absolute", left: unitWidth * time }}
      >
        <TimeScale textWidth={textWidth} ref={textRef}>
          {toTimeFormattedText(time)}
        </TimeScale>
        <TimeScaleLine ref={scaleLineRef} />
      </TimeScaleWrapper>
    );
  }
);

export default TimelineHeader;

const StyledTimelineHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  border-bottom: 1px solid #dcdde3;
  height: 20px;
`;

const TimeScale = styled.div<{ textWidth: number }>`
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #a2a6b1;
  text-align: center;
  width: ${(props) => `${props.textWidth}px`};
`;

const TimeScaleLine = styled.div`
  position: absolute;
  top: 16px;
  height: 8px;
  width: 1px;
  background-color: #dcdde3;
`;

const TimeScaleWrapper = styled.div<{ width: number; textWidth: number }>`
  width: ${(props) => props.width || 0}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${TimeScale} {
    margin-left: ${(props) => -`${props.textWidth / 2}`}px;
  }
`;
