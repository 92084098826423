/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import VideoEditor, { IProject, ISelection } from "../../../../Programs/VideoEditor/Controllers/VideoEditor";
import VideoPlayerComponent from "./Video";
import styled from "styled-components";
import CurrentTextSource from "./CurrentTextSource";
import PlayerManager from "../../../../Programs/VideoEditor/VideoPlayer";
import PlayControlComponent from "./PlayControl";
import Timeline from "./Timeline";
import { parseQuery } from "../MainView";
import ExportButton from "./ExportButton";
import { ZIndex } from "../ZIndex";
import { closeProgram } from "../../MainTemplate";

import DownArrow from "../../../../assets/images/stroke-1-copy-3@3x.png";
import UpArrow from "../../../../assets/images/stroke-1-copy-3.svg";
import CloseIcon from "../../../../assets/images/icn-close.svg";
import { IAudioSource, ITextSource, IVideoSource } from "../../../../Programs/Interfaces";

export const getFileId = (location: Location) => {
  const split = location.pathname.split("/");
  const findBeforeFileIdPath = (element: string) => element === "videoEditor";
  const fileIdIndex = split.findIndex(findBeforeFileIdPath) + 1;
  return split[fileIdIndex];
};

export const getSelection = (location: Location) => {
  const search = location.search;
  const params = parseQuery(search);
  const startTimeIndex = params.findIndex((element) => element[0] === "startTimeInSeconds");
  const endTimeIndex = params.findIndex((element) => element[0] === "endTimeInSeconds");
  return {
    startTimeInSeconds: params[startTimeIndex] ? params[startTimeIndex][1] : undefined,
    endTimeInSeconds: params[endTimeIndex] ? params[endTimeIndex][1] : undefined,
  };
};

const VideoEditorPage = () => {
  const location = useLocation();
  const fileId = getFileId(location);
  const [title, setTitle] = useState<string>("");
  const [project, setProject] = useState<IProject>();
  const editor = React.useMemo(() => new VideoEditor(), [fileId]);

  useEffect(() => {
    const subscription = editor.subscribeEditorChanges((editorProject) => {
      setProject({ ...editorProject });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [editor, project]);

  const getVideoSource = React.useCallback(() => {
    editor.importVideoSource(fileId).then((videoSource) => {
      setTitle(videoSource.data.name);
    });
  }, [editor, fileId]);

  const getAudioSource = useCallback(() => {
    editor.importAudioSource(fileId);
  }, [editor, fileId]);

  const getTextSource = useCallback(() => {
    editor.importTextSource(fileId);
  }, [editor, fileId]);

  useEffect(() => {
    getVideoSource();
  }, [getVideoSource]);

  useEffect(() => {
    getAudioSource();
  }, [getAudioSource]);

  useEffect(() => {
    getTextSource();
  }, [getTextSource]);

  const videoSource: IVideoSource | undefined = project?.sources
    .filter((s) => s.type === "VIDEO")
    .pop() as IVideoSource;

  const audioSource: IAudioSource | undefined = project?.sources
    .filter((s) => s.type === "AUDIO")
    .pop() as IAudioSource;

  const textSource: ITextSource | undefined = project?.sources.filter((s) => s.type === "TEXT").pop() as ITextSource;

  const [videoPlayer, setVideoPlayer] = useState<PlayerManager | null>(null);
  const durationInMilliSeconds = useMemo(() => (videoPlayer?.getDuration() || 0) * 1000, [videoPlayer]);
  const seek = useCallback(
    (timeInMilliSeconds: number) => {
      videoPlayer?.seek(timeInMilliSeconds / 1000);
    },
    [videoPlayer]
  );

  const subscribeTimeInMilliSeconds = useCallback(
    (f: (timeInMilliSeconds: number) => void) => {
      const subscription = videoPlayer?.subscribeTime((time: number) => f(time * 1000));
      return subscription;
    },
    [videoPlayer]
  );

  const pause = useCallback(() => {
    videoPlayer?.pause();
  }, [videoPlayer]);

  const [minimizedEditor, setMinimizeEditor] = useState(false);

  const selectionQuery = getSelection(location);
  const parsedSelection: ISelection | null =
    selectionQuery == null || selectionQuery.startTimeInSeconds == null || selectionQuery.endTimeInSeconds == null
      ? null
      : {
          startTimeInMillSeconds: Number(selectionQuery.startTimeInSeconds) * 1000,
          endTimeInMillSeconds: Number(selectionQuery.endTimeInSeconds) * 1000,
        };

  const [isSelectingMode, setIsSelectionMode] = useState(parsedSelection != null);

  const [selection, setSelection] = useState<ISelection | null>(parsedSelection);

  useEffect(() => {
    if (parsedSelection?.startTimeInMillSeconds && videoPlayer) {
      seek(parsedSelection.startTimeInMillSeconds);
      setSelection(parsedSelection);
      setIsSelectionMode(true);
    }
  }, [videoPlayer, parsedSelection?.startTimeInMillSeconds, parsedSelection?.endTimeInMillSeconds]);

  useEffect(() => {
    if (!isSelectingMode) {
      setSelection(null);
    }

    if (isSelectingMode && selection == null) {
      if (videoPlayer != null && durationInMilliSeconds != null) {
        const startTimeInMillSeconds = videoPlayer?.getTime() * 1000;
        const endTimeInMillSeconds = Math.min(startTimeInMillSeconds + 15000, durationInMilliSeconds);
        setSelection({ startTimeInMillSeconds, endTimeInMillSeconds });
      }
    }
  }, [isSelectingMode, videoPlayer, selection, durationInMilliSeconds]);

  const editorOverlayRef = useRef<HTMLDivElement | null>(null);
  const closeWhenClickOverlay = (e: MouseEvent) => {
    if (e.target === editorOverlayRef.current) {
      setMinimizeEditor(true);
    }
  };
  useEffect(() => {
    editorOverlayRef.current?.addEventListener("click", closeWhenClickOverlay);

    return () => {
      editorOverlayRef.current?.removeEventListener("click", closeWhenClickOverlay);
    };
  });

  const [isExporting, setIsExporting] = useState(false);

  if (editor == null || project == null) {
    return null;
  }

  const ExportButtons = (
    <ExportButton
      selection={selection}
      isSelectingMode={isSelectingMode}
      setIsSelectionMode={setIsSelectionMode}
      editor={editor}
      initialFileName={title}
      videoSource={videoSource}
      isExporting={isExporting}
      setIsExporting={setIsExporting}
    />
  );

  return (
    <EditorOverlay minimized={minimizedEditor} ref={editorOverlayRef}>
      <StyledVideoEditor>
        <EditorHeader>
          <EditorTitle>
            <EditorType>영상편집</EditorType>
            <FileName>{title}</FileName>
          </EditorTitle>

          <Icons>
            {minimizedEditor ? ExportButtons : null}
            <EditorToggleArrow
              onClick={() => {
                if (minimizedEditor) {
                  setMinimizeEditor(false);
                } else {
                  setMinimizeEditor(true);
                }
              }}
              src={minimizedEditor ? UpArrow : DownArrow}
            />
            <EditorCloseIcon
              src={CloseIcon}
              onClick={() => {
                closeProgram(fileId);
              }}
            />
          </Icons>
        </EditorHeader>
        <StyledVideoEditorBody minimized={minimizedEditor}>
          <VideoAndTextSourceWrapper>
            <VideoPlayerWrapper>
              <VideoPlayerComponent
                editor={editor}
                playerManager={videoPlayer}
                videoSource={videoSource}
                setVideoPlayer={setVideoPlayer}
              />
            </VideoPlayerWrapper>
            <TextSourceWrapper>
              {textSource && videoPlayer && (
                <CurrentTextSource videoPlayer={videoPlayer} textSource={textSource} title={title} />
              )}
            </TextSourceWrapper>
          </VideoAndTextSourceWrapper>
          <PlayControlAndExportButtons>
            <PlayControlWrapper>
              {videoPlayer && <PlayControlComponent playerManager={videoPlayer} />}
            </PlayControlWrapper>
            <ExportButtonsWrapper>{ExportButtons}</ExportButtonsWrapper>
          </PlayControlAndExportButtons>
          {durationInMilliSeconds && videoPlayer && (
            <Timeline
              textSource={textSource}
              audioSource={audioSource}
              selection={selection}
              select={setSelection}
              durationInMilliSeconds={durationInMilliSeconds}
              seek={seek}
              subscribeTime={subscribeTimeInMilliSeconds}
              pause={pause}
            />
          )}
        </StyledVideoEditorBody>
      </StyledVideoEditor>
    </EditorOverlay>
  );
};

const EditorType = styled.div`
  font-family: NanumSquareB;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33343d;
  white-space: nowrap;
`;
const FileName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33343d;
`;

const EditorTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${EditorType} {
    margin-right: 10px;
  }
`;

const EditorToggleArrow = styled.img`
  width: 15px;
  height: 14px;
`;

const EditorCloseIcon = styled.img`
  width: 15px;
  height: 14px;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${EditorToggleArrow} {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const EditorHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const VideoPlayerWrapper = styled.div`
  flex: 1 1 660px;
`;
const TextSourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 13px 12px 13px 17px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #f7f7f7;
  white-space: pre-wrap;
  word-break: break-all;
`;

const ExportButtonsWrapper = styled.div``;

const VideoAndTextSourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 372px;
  ${VideoPlayerWrapper} {
    flex: 1;
    margin-right: 18px;
  }

  ${TextSourceWrapper} {
    flex: 1;
    overflow-y: scroll;
  }
`;

const PlayControlWrapper = styled.div``;

const PlayControlAndExportButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 24px;
`;

const StyledVideoEditorBody = styled.div<{ minimized: boolean }>`
  ${(props) => (props.minimized ? "height: 0px; overflow-y:hidden;transition:height 1s ease;" : undefined)}
`;

const StyledVideoEditor = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 19px 14px 22px;
  box-sizing: border-box;
  border-radius: 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  ${EditorTitle} {
  }
`;

const EditorOverlay = styled.div<{ minimized: boolean }>`
  position: absolute;
  z-index: ${ZIndex.programPopupZIndex};
  top: ${(props) => (props.minimized ? undefined : 0)};
  bottom: ${(props) => (props.minimized ? 0 : undefined)};
  width: 100%;
  height: ${(props) => (props.minimized ? "100px" : "100%")};
  background-color: ${(props) => (props.minimized ? undefined : "rgba(0, 0, 0, 0.5)")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //padding-top: 100px;
  //box-sizing: border-box;

  ${StyledVideoEditor} {
    //max-height: 762px;
  }
`;

export default VideoEditorPage;
