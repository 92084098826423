import * as React from "react";
import { useContext, useState } from "react";
import DropDown from "../../Dropdown";
import { ISplitDoneResponseInfo, ISplitVideoHandlers } from "../../../../Programs/VideoEditor/Usecases/SplitVideo";
import SaveToArchivePopup from "./SaveToArchivePopup";
import styled from "styled-components";
import { PopupContext } from "../../../Contexts/PopupContext";
import VideoEditor, { ISelection } from "../../../../Programs/VideoEditor/Controllers/VideoEditor";
import LoadingGIF from "../../../../assets/images/loading.gif";
import { CreateFileManagerContext } from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";
import { downloadFileFromLink } from "../../../../utils/utils";
import DownloadIcon from "../../../../assets/images/download-24-px.svg";
import ArchiveIcon from "../../../../assets/images/1-icon-folder-open-20-px.svg";
import { IVideoSource } from "../../../../Programs/Interfaces";

export const ExportButton = (props: {
  selection: ISelection | null;
  isSelectingMode: boolean;
  setIsSelectionMode: (state: boolean) => void;
  editor: VideoEditor;
  videoSource: IVideoSource;
  isExporting: boolean;
  setIsExporting: (state: boolean) => void;
  initialFileName: string;
}) => {
  const {
    isSelectingMode,
    setIsSelectionMode,
    selection,
    editor,
    videoSource,
    isExporting,
    setIsExporting,
    initialFileName,
  } = props;

  const [exportDropdownToggled, setExportDropdownToggled] = useState(false);
  const { showPopup } = useContext(PopupContext);
  const { startVideoFileCreateProcessFromCreateFile } = useContext(CreateFileManagerContext);

  const convertToFileName = (name: string, selectionRange: ISelection) => {
    const nameArray = name.split(".");
    let convertedNameArray: string[] = [];
    nameArray.forEach((value, index) => {
      if (index === nameArray.length - 1) {
        convertedNameArray.push(
          `_${Math.floor(selectionRange.startTimeInMillSeconds / 1000)}_${Math.floor(
            selectionRange.endTimeInMillSeconds / 1000
          )}`
        );
      }

      convertedNameArray.push(value);
    });

    return "[vws]" + convertedNameArray.join(".");
  };

  const ExportButtonsWithDropDown = (
    <DropDown
      toggled={exportDropdownToggled}
      setToggled={setExportDropdownToggled}
      items={[
        {
          key: "archive",
          icon: ArchiveIcon,
          name: "내 아카이브에 저장",
          onClick: () => {
            if (selection) {
              let isSplitFinished = false;
              let s3Url: string | null = null;
              let s3Path: string | null = null;
              let selectedDirectory: string | null = null;
              let fileName: string | null = null;

              const handlers = {
                onRequest: () => {
                  setIsExporting(true);
                  showPopup!(
                    <SaveToArchivePopup
                      initialFileName={convertToFileName(initialFileName, selection)}
                      onCancel={() => {
                        setIsExporting(false);
                      }}
                      onConfirm={(selectedDirectoryId, name) => {
                        if (isSplitFinished && s3Url && s3Path) {
                          setIsExporting(false);
                          startVideoFileCreateProcessFromCreateFile!(selectedDirectoryId, s3Path, s3Url, name);
                        } else {
                          selectedDirectory = selectedDirectoryId;
                          fileName = name;
                        }
                      }}
                    />
                  );
                },
                onDone: (response: ISplitDoneResponseInfo) => {
                  if (selectedDirectory && fileName && response.result[0]) {
                    const url = response.result[0].outputFileS3Url;
                    const path = response.result[0].outputFileS3Path;
                    startVideoFileCreateProcessFromCreateFile!(selectedDirectory, path, url, fileName);
                    setIsExporting(false);
                  } else {
                    isSplitFinished = true;
                    s3Url = response.result[0].outputFileS3Url;
                    s3Path = response.result[0].outputFileS3Path;
                  }
                },
              };
              editor.splitVideo(videoSource.data.fileId, selection, handlers);
            }
          },
        },
        {
          key: "local",
          icon: DownloadIcon,
          name: "내 컴퓨터로 다운로드",
          onClick: () => {
            if (selection) {
              setExportDropdownToggled(false);

              const handlers: ISplitVideoHandlers = {
                onRequest: () => {
                  setIsExporting(true);
                },
                onDone: (response) => {
                  response.result.forEach((result) => {
                    downloadFileFromLink(result.outputFileS3Url, convertToFileName(initialFileName, selection));
                  });
                  setExportDropdownToggled(false);
                  setIsExporting(false);
                },
              };
              editor.splitVideo(videoSource.data.fileId, selection, handlers);
            }
          },
        },
      ]}
    >
      <ExportSplitVideoButton>편집영상 내보내기</ExportSplitVideoButton>
    </DropDown>
  );

  const ExportingButton = (
    <ExportingSplitVideoButton>
      <img style={{ width: "21px", height: "20px" }} src={LoadingGIF} alt={"내보내기 중"} />
      내보내기 중
    </ExportingSplitVideoButton>
  );

  return isSelectingMode ? (
    <>
      <ExportButtonsWrapper>
        {!isExporting && (
          <ExportCancelVideoButton
            onClick={() => {
              setIsSelectionMode(false);
            }}
          >
            취소
          </ExportCancelVideoButton>
        )}
        {isExporting ? ExportingButton : ExportButtonsWithDropDown}
      </ExportButtonsWrapper>
    </>
  ) : (
    <ConvertSplitVideoButton
      onClick={() => {
        setIsSelectionMode(true);
      }}
    >
      구간편집
    </ConvertSplitVideoButton>
  );
};

export default ExportButton;

const ConvertSplitVideoButton = styled.button`
  padding: 9px 11px 10px 12px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #657eff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
`;

const ExportCancelVideoButton = styled.button`
  padding: 10px 12px 9px;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f0f1f3;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #414245;
  cursor: pointer;
  white-space: nowrap;
`;

const ExportSplitVideoButton = styled.button`
  padding: 9px 11px 10px 12px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #657eff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
`;

const ExportingSplitVideoButton = styled.button`
  padding: 6px 11px 6px 12px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #657eff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;

  & > img {
    margin-right: 8px;
  }
`;

const ExportButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${ExportCancelVideoButton} {
    margin-right: 11px;
  }
`;
