import { IUploadHandlers } from "../../Interfaces";
import { FileStatus, IFileDTO } from "../../../Models/Interfaces";
import { toS3Path } from "../../../utils/utils";
import { FileUseCases } from "../File";
import AudioExtractDriver, { IAudioDriver, IAudioExtractHandlers } from "../../../Drivers/AudioExtract";
import AnalysisDriver, { IAnalysisDriver, IAnalysisHandler } from "../../../Drivers/Analysis";
import MultipartUploadDriver from "../../../Drivers/MultipartUpload";

export interface IFileCreateHandlers {
  uploadHandlers?: IUploadHandlers;
  fileCreated?: (file: IFileDTO) => void;
  audioExtractHandlers?: IAudioExtractHandlers;
  audioWaveExtractHandlers?: IAudioExtractHandlers;
  analyzeHandlers?: IAnalysisHandler;
}

export interface ICreateFileFromUploadInfo {
  type: "UPLOAD";
  directoryId: string;
  file: File;
  name: string;
  handlers?: IFileCreateHandlers;
}

export interface ICreateFileFromAlreadyUploadInfo {
  type: "ALREADY_UPLOAD";
  directoryId: string;
  s3Path: string;
  s3Url: string;
  name: string;
  handlers?: IFileCreateHandlers;
}

export type ICreateFileInfo = ICreateFileFromUploadInfo | ICreateFileFromAlreadyUploadInfo;

export interface IVideoFileCreateUseCase {
  createVideoFile: (createFileInfo: ICreateFileInfo) => Promise<IFileDTO>;
  getProcessingFiles: () => Promise<IFileDTO[]>;
  checkAfterCreateProcess: (fileId: string, handlers?: IFileCreateHandlers) => Promise<void>;
}

class VideoFileUseCase extends FileUseCases implements IVideoFileCreateUseCase {
  private audioExtractDriver: IAudioDriver;
  private analysisDriver: IAnalysisDriver;

  constructor() {
    super();
    this.audioExtractDriver = new AudioExtractDriver();
    this.analysisDriver = new AnalysisDriver();
  }

  public createVideoFile = async (createFileInfo: ICreateFileInfo) => {
    if (createFileInfo.type === "UPLOAD") {
      return await this.requestCreateFileFromUpload(
        createFileInfo.directoryId,
        createFileInfo.file,
        createFileInfo.name,
        createFileInfo.handlers
      );
    }

    if (createFileInfo.type === "ALREADY_UPLOAD") {
      return await this.requestCreateWithUploadedFile(
        createFileInfo.directoryId,
        createFileInfo.s3Path,
        createFileInfo.s3Url,
        createFileInfo.name,
        createFileInfo.handlers
      );
    }

    throw new Error("not support type");
  };

  private requestCreateFileFromUpload = async (
    directoryId: string,
    file: File,
    name: string,
    handlers?: IFileCreateHandlers
  ) => {
    const { s3Path, s3Url } = await this.uploadFile(file, name, handlers?.uploadHandlers);
    const fileDTO = await this.requestCreateWithUploadedFile(directoryId, s3Path, s3Url, name, handlers);
    return fileDTO;
  };

  private requestCreateWithUploadedFile = async (
    directoryId: string,
    s3Path: string,
    s3Url: string,
    name: string,
    handlers?: IFileCreateHandlers
  ) => {
    const fileDTO = await this.createFile(directoryId, name, s3Path, s3Url);

    if (handlers?.fileCreated) {
      handlers.fileCreated(fileDTO);
    }

    await this.checkAfterCreateProcess(fileDTO.id, handlers);
    const file = await this.getFile(fileDTO.id);
    return file;
  };

  public checkAfterCreateProcess = async (fileId: string, handlers?: IFileCreateHandlers) => {
    await this.waitFileIsAnalyzing(fileId);
    await this.checkAudioExtract(fileId, handlers?.audioExtractHandlers);
    await this.checkAudioWaveFormExtract(fileId, handlers?.audioWaveExtractHandlers);
    await this.checkAnalyze(fileId, handlers?.analyzeHandlers);
  };

  public getProcessingFiles = async () => {
    const fileIds = await this.repository.getProcessingFiles();
    const getProcessingFilesPromises = fileIds.map(async (id) => await this.getFile(id));
    const fileDTOs = await Promise.all(getProcessingFilesPromises);
    return fileDTOs;
  };

  private uploadFile = async (file: File, name?: string, uploadHandlers?: IUploadHandlers) => {
    const uploadInstance = new MultipartUploadDriver();

    const cancelUpload = () => {
      uploadInstance.cancelUpload();
    };

    if (uploadHandlers?.addCancelSubscribers) {
      uploadHandlers.addCancelSubscribers(cancelUpload);
    }

    const uploadResult = await uploadInstance.upload(file, name, uploadHandlers);

    const s3Url = uploadResult.location;
    const s3Path = toS3Path(uploadResult.location);

    return { s3Url, s3Path };
  };

  private waitFileIsAnalyzing = async (fileId: string) => {
    return new Promise<boolean>((resolve) => {
      const checker = () => {
        const interval = window.setInterval(() => {
          this.getFile(fileId)
            .then((file) => {
              if (file.status === FileStatus.ANALYZING) {
                clearInterval(interval);
                resolve(true);
              }
            })
            .catch(() => {
              clearInterval(interval);
              throw new Error("");
            });
        }, 2000);
      };

      checker();
    });
  };

  private checkAudioExtract = (fileId: string, audioExtractHandlers?: IAudioExtractHandlers) => {
    return new Promise((resolve, reject) => {
      const handlers: IAudioExtractHandlers = {
        onRequest: audioExtractHandlers?.onRequest,
        onProgress: audioExtractHandlers?.onProgress,
        onDone: () => {
          if (audioExtractHandlers?.onDone) {
            audioExtractHandlers.onDone();
          }
          resolve(true);
        },
        onCanceled: () => {
          if (audioExtractHandlers?.onCanceled) {
            audioExtractHandlers.onCanceled();
          }
          reject(false);
        },
      };
      this.audioExtractDriver.checkExtractAudio(fileId, handlers);
    });
  };

  private checkAudioWaveFormExtract = (fileId: string, audioExtractHandlers?: IAudioExtractHandlers) => {
    return new Promise((resolve, reject) => {
      const handlers: IAudioExtractHandlers = {
        onProgress: audioExtractHandlers?.onProgress,
        onDone: () => {
          if (audioExtractHandlers?.onDone) {
            audioExtractHandlers.onDone();
          }
          resolve(true);
        },
        onCanceled: () => {
          if (audioExtractHandlers?.onCanceled) {
            audioExtractHandlers.onCanceled();
          }
          reject(false);
        },
      };
      this.audioExtractDriver.checkExtractWaveForm(fileId, handlers);
    });
  };

  private checkAnalyze = (fileId: string, analysisHandlers?: IAnalysisHandler) => {
    return new Promise((resolve, reject) => {
      const handlers: IAnalysisHandler = {
        onProgress: analysisHandlers?.onProgress,
        onDone: () => {
          if (analysisHandlers?.onDone) {
            analysisHandlers.onDone(fileId);
          }

          resolve(true);
        },
        onCanceled: () => {
          if (analysisHandlers?.onCanceled) {
            analysisHandlers.onCanceled();
          }
          reject(false);
        },
      };
      this.analysisDriver.checkAnalysis(fileId, handlers);
    });
  };
}

export default VideoFileUseCase;
