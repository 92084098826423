import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as R from "ramda";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";
import { changeFileExt, createBlob, downloadFile } from "../../../../../utils/utils";
import { ITextScript, ITextSource } from "../../../../../Programs/Interfaces";
import TextSourceUseCase from "../../../../../Programs/VideoEditor/Usecases/TextSource";
import { TextScriptExportType } from "../../../../../Programs/VideoEditor/Services/TextSource";

const CurrentTextSource = (props: { videoPlayer: PlayerManager; textSource: ITextSource; title: string }) => {
  const { videoPlayer, textSource, title } = props;
  const [focusScript, setFocusScript] = useState<ITextScript>();
  const [isSubtitleSelecting, setIsSubtitleSelecting] = useState(false);

  useEffect(() => {
    videoPlayer?.subscribeTime((time: number) => {
      const timeInMilliSeconds = time * 1000;
      const targetScript = textSource.data.scripts
        .filter((script) => script.startTime <= timeInMilliSeconds && script.endTime >= timeInMilliSeconds)
        .pop();

      if (!R.equals(targetScript, focusScript)) {
        setFocusScript(targetScript);
      }
    });
  }, [videoPlayer, focusScript, textSource]);

  const downloadScript = (type: TextScriptExportType) => {
    const result = new TextSourceUseCase().exportTextSource(type, textSource);
    const blob = createBlob("text", result);
    const fileName = changeFileExt(title, type.toLocaleLowerCase());
    downloadFile(blob, fileName);
  };

  const toggleDownloadScriptButtonRef = useRef<HTMLButtonElement | null>(null);

  const closeToggleDownloadButton = useCallback((e: MouseEvent) => {
    if (toggleDownloadScriptButtonRef.current != null) {
      const node = e.target as Node;
      if (!toggleDownloadScriptButtonRef.current.contains(node)) {
        setIsSubtitleSelecting(false);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", closeToggleDownloadButton);
    return () => {
      window.removeEventListener("click", closeToggleDownloadButton);
    };
  }, [closeToggleDownloadButton]);

  return (
    <StyledCurrentTextSource>
      <Text>{focusScript?.data.text || " "}</Text>
      <ExportScriptWrapper>
        <ToggleDownloadScriptButton
          ref={toggleDownloadScriptButtonRef}
          onClick={() => {
            setIsSubtitleSelecting(!isSubtitleSelecting);
          }}
        >
          스크립트 내보내기
        </ToggleDownloadScriptButton>
        {isSubtitleSelecting &&
          Object.keys(TextScriptExportType).map((type) => (
            <DownloadScriptButton
              key={type}
              onClick={() => {
                downloadScript(type as TextScriptExportType);
                setIsSubtitleSelecting(false);
              }}
            >
              {type.toLowerCase()}
            </DownloadScriptButton>
          ))}
      </ExportScriptWrapper>
    </StyledCurrentTextSource>
  );
};

export default CurrentTextSource;
//
// const SearchTargetText = styled.div`
//   color: #657eff;
// `;

const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #72757b;
  white-space: pre;
`;

const ToggleDownloadScriptButton = styled.button`
  padding: 9px 11px 10px 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  background-color: #657eff;
  color: #ffffff;
  cursor: pointer;
`;

const DownloadScriptButton = styled.button`
  padding: 8px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 1;
  background-color: #ffffff;
  border: solid 1px #edeef4;
  font-size: 12px;
  cursor: pointer;
`;

const ExportScriptWrapper = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  ${DownloadScriptButton} {
    margin-right: 10px;
  }
`;

const StyledCurrentTextSource = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
