import { ITextScript, ITextSource } from "../../Interfaces";
// @ts-ignore
import Subsrt from "subsrt";

export enum TextScriptExportType {
  SRT = "SRT",
  TXT = "TXT",
  SMI = "SMI",
}

interface ISubSrtConverterCaption {
  start: number; //Time to show caption in milliseconds
  end: number; //Time to hide caption in milliseconds
  text: string;
}

export interface ITextSourceService {
  convert2SMI: (textSource: ITextSource) => string;
  convert2SRT: (textSource: ITextSource) => string;
  convert2TXT: (textSource: ITextSource) => string;
}

class TextSourceService implements ITextSourceService {
  public convert2SRT = (textSource: ITextSource) => {
    const options = { format: "srt" };
    const captions: ISubSrtConverterCaption[] = convert2SRTCaptions(textSource.data.scripts);
    const srt = Subsrt.build(captions, options);
    return srt;
  };

  public convert2TXT = (textSource: ITextSource) => {
    const txt = textSource.data.scripts
      .map((script) => `${script.startTime / 1000}sec_${script.endTime / 1000}sec${"\n"}${script.data.text}`)
      .join("\n\n\n");
    return txt;
  };

  public convert2SMI = (textSource: ITextSource) => {
    const options = { format: "smi" };
    const captions: ISubSrtConverterCaption[] = convert2SRTCaptions(textSource.data.scripts);
    const srt = Subsrt.build(captions, options);
    return srt;
  };
}

const convert2SRTCaptions = (scripts: ITextScript[]) => {
  return scripts.map((script) => ({
    start: script.startTime,
    end: script.endTime,
    text: script.data.text,
  }));
};

export default TextSourceService;
