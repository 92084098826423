import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ISelection } from "../../../../../Programs/VideoEditor/Controllers/VideoEditor";
import LeftArrow from "../../../../../assets/images/icn-arrow-to-left@3x.png";
import RightArrow from "../../../../../assets/images/icn-arrow-to-right@3x.png";

const SelectionBox = React.memo(
  (props: {
    durationInMilliSeconds: number;
    selection: ISelection | null;
    select: (selection: ISelection | null) => void;
    parentWidth: number;
    height?: number;
    pause: () => void;
  }) => {
    const { selection, select, height, parentWidth, durationInMilliSeconds, pause } = props;

    const [isClicked, setIsClicked] = useState(false);
    const [isBarClicked, setIsBarClicked] = useState(false);
    const [modifyBarTarget, setModifyBarTarget] = useState<"start" | "end" | null>(null);

    const [tempSelection, setTempSelection] = useState<ISelection | null>(selection);

    useEffect(() => {
      setTempSelection(selection);
    }, [selection]);

    const moveSelection = useCallback(
      (diff: number) => {
        if (
          tempSelection &&
          tempSelection?.startTimeInMillSeconds != null &&
          tempSelection?.endTimeInMillSeconds != null
        ) {
          let startTimeInMillSeconds;
          let endTimeInMillSeconds;

          const originalRange = tempSelection.endTimeInMillSeconds - tempSelection.startTimeInMillSeconds;

          if (diff < 0) {
            startTimeInMillSeconds = Math.max(tempSelection.startTimeInMillSeconds + diff, 0);

            endTimeInMillSeconds = startTimeInMillSeconds + originalRange;
          } else {
            endTimeInMillSeconds = Math.min(tempSelection.endTimeInMillSeconds + diff, durationInMilliSeconds);

            startTimeInMillSeconds = endTimeInMillSeconds - originalRange;
          }

          setTempSelection({
            startTimeInMillSeconds,
            endTimeInMillSeconds,
          });
        }
      },
      [durationInMilliSeconds, tempSelection]
    );

    const onSelectionMove = useCallback(
      (e: React.MouseEvent | MouseEvent) => {
        const movement = (e.movementX / parentWidth) * durationInMilliSeconds;
        moveSelection(movement);
      },
      [parentWidth, durationInMilliSeconds, moveSelection]
    );

    const onMouseDown = (e: React.MouseEvent | MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      setIsClicked(true);
      pause();
    };

    const onMouseMove = useCallback(
      (e: React.MouseEvent | MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (isClicked) {
          onSelectionMove(e);
        }
      },
      [isClicked, onSelectionMove]
    );

    const onMouseUp = useCallback(
      (e: React.MouseEvent | MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setIsClicked(false);
        select(tempSelection);
      },
      [select, tempSelection, setIsClicked]
    );

    useEffect(() => {
      document.addEventListener("mousemove", onMouseMove);
      return () => {
        document.removeEventListener("mousemove", onMouseMove);
      };
    }, [selection, tempSelection, onMouseMove]);

    useEffect(() => {
      document.addEventListener("mouseup", onMouseUp);
      return () => {
        document.removeEventListener("mouseup", onMouseUp);
      };
    }, [selection, tempSelection, onMouseUp]);

    const modifySelection = useCallback(
      (type: "start" | "end", diff: number) => {
        if (
          tempSelection == null ||
          tempSelection.startTimeInMillSeconds == null ||
          tempSelection.endTimeInMillSeconds == null
        ) {
          return;
        }
        if (type === "start") {
          setTempSelection({
            startTimeInMillSeconds: Math.max(tempSelection.startTimeInMillSeconds + diff, 0),
            endTimeInMillSeconds: tempSelection.endTimeInMillSeconds,
          });
        }

        if (type === "end") {
          setTempSelection({
            startTimeInMillSeconds: tempSelection.startTimeInMillSeconds,
            endTimeInMillSeconds: Math.min(tempSelection.endTimeInMillSeconds + diff, durationInMilliSeconds),
          });
        }
      },
      [durationInMilliSeconds, tempSelection]
    );

    const onBarMouseDown = (type: "start" | "end", e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setIsBarClicked(true);
      setModifyBarTarget(type);
      pause();
    };

    const onBarMouseMove = useCallback(
      (e: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isBarClicked && modifyBarTarget != null) {
          const movement = (e.movementX / parentWidth) * durationInMilliSeconds;
          modifySelection(modifyBarTarget, movement);
        }
      },
      [isBarClicked, modifyBarTarget, parentWidth, durationInMilliSeconds, modifySelection]
    );

    const onBarMouseUp = useCallback(
      (e: React.MouseEvent | MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsBarClicked(false);
        setModifyBarTarget(null);
        select(tempSelection);
      },
      [setIsBarClicked, setModifyBarTarget, select, tempSelection]
    );

    useEffect(() => {
      document.addEventListener("mousemove", onBarMouseMove);
      return () => {
        document.removeEventListener("mousemove", onBarMouseMove);
      };
    }, [selection, tempSelection, onBarMouseMove]);

    useEffect(() => {
      document.addEventListener("mouseup", onBarMouseUp);
      return () => {
        document.removeEventListener("mouseup", onBarMouseUp);
      };
    }, [selection, tempSelection, onBarMouseUp]);

    const showingSelection = tempSelection || selection;

    if (showingSelection == null || showingSelection.endTimeInMillSeconds == null) {
      return null;
    }

    const width =
      ((showingSelection.endTimeInMillSeconds - showingSelection.startTimeInMillSeconds) * parentWidth) /
      durationInMilliSeconds;

    const left = (showingSelection.startTimeInMillSeconds / durationInMilliSeconds) * parentWidth;

    return (
      <SelectionBoxWrapper left={left} height={height} width={width} onMouseDown={onMouseDown}>
        <StyledSelectionBox
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <StyledLeftModifyBorder onMouseDown={(e) => onBarMouseDown("start", e)} src={LeftArrow} />
          <StyledRightModifyBorder onMouseDown={(e) => onBarMouseDown("end", e)} src={RightArrow} />
        </StyledSelectionBox>
      </SelectionBoxWrapper>
    );
  }
);

export default SelectionBox;

const StyledLeftModifyBorder = styled.img`
  cursor: ew-resize;
`;

const StyledRightModifyBorder = styled.img`
  cursor: ew-resize;
`;

const StyledSelectionBox = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  background-color: transparent;

  ${StyledLeftModifyBorder} {
    position: absolute;
    border-radius: 10px;
    top: -18px;
    left: 5px;
    width: 10px;
    background-color: #657eff;
  }

  ${StyledRightModifyBorder} {
    position: absolute;
    border-radius: 10px;
    top: -18px;
    right: 5px;
    width: 10px;
    background-color: #657eff;
  }
`;

const SelectionBoxWrapper = styled.div<{
  width: number;
  left: number;
  height?: number;
}>`
  position: absolute;
  left: ${(props) => props.left}px;
  padding: 4px 5px 1px 3px;
  border-radius: 10px;
  border-left: 3px solid #657eff;
  border-right: 3px solid #657eff;
  border-top: 20px solid #657eff;
  border-bottom: 3px solid #657eff;
  box-sizing: border-box;
  width: ${(props) => props.width}px;
  height: 100%;
  background-color: transparent;
  cursor: grab;
`;
