import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { PopupContext } from "../../../Contexts/PopupContext";
import { DirectoryUseCases } from "../../../../UseCases/Directory/Directory";

const CreateDirectoryPopup = (props: {
  directoryId: string;
  defaultName: string;
  onCreateDirectory: (fileName: string) => void;
}) => {
  const { closePopup } = useContext(PopupContext);
  const { defaultName, onCreateDirectory, directoryId } = props;
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    new DirectoryUseCases()
      .convertDirectoryNameIfDuplicated(directoryId, defaultName)
      .then((name) => setFileName(name));
  }, [defaultName, directoryId]);

  const onConfirm = () => {
    if (fileName == null || fileName.trim().length === 0) {
      alert("폴더 이름을 적어주세요");
      return;
    }

    onCreateDirectory(fileName);
    if (closePopup) {
      closePopup();
    }
  };

  return (
    <PopupWrapper>
      <StyledPopup>
        <PopupTitle>새 폴더 추가</PopupTitle>
        <FileNameInput value={fileName} onChange={(e) => setFileName(e.currentTarget.value)} />
        <ButtonsWrapper>
          <CancelButton onClick={closePopup}>취소</CancelButton>
          <ConfirmButton onClick={onConfirm}>확인</ConfirmButton>
        </ButtonsWrapper>
      </StyledPopup>
    </PopupWrapper>
  );
};

const PopupTitle = styled.div`
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #33343d;
`;

const FileNameInput = styled.input`
  margin: 14px 0;
  padding: 16px 19px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px #e3e5ee;
  background-color: #f7f7f7;
  color: #72757b;
`;

const ConfirmButton = styled.button`
  padding: 13px 19px 12px 20px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #657eff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const CancelButton = styled.button`
  margin: 0 10px 0 0;
  padding: 13px 21px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4e5968;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${ConfirmButton} {
    margin-left: 10px;
  }
`;

const StyledPopup = styled.div`
  width: 351px;
  height: 184px;
  padding: 17px 19px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

const PopupWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default CreateDirectoryPopup;
