import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toFormattedLocaleDateAndTimeString, toFormattedSize } from "../../../../../utils/utils";
import { SORT } from "../../../../hooks/useFileSort";
import DownArrowIcon from "../../../../../assets/images/icn-arrow-down-copy-2.svg";
import CheckBox from "../../CheckBox";
import { closeContextMenu } from "../../../GlobalContextMenu";
import { DEFAULT_SHOW_TEXT_LENGTH } from "../GridViewFile/GridViewItem";
import { getEllipsisText } from "../Utils";

const ListViewItem = (props: {
  icon: string;
  name: string;
  size: number;
  createdDateTime: string;
  selected: boolean;
  isShowCheck: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
  onRightClick?: (e: React.MouseEvent, onNameChange: () => void, onDeleteChange: () => void) => void;
  onNameChange: (name: string) => void;
  onDragStart?: () => void;
  onDragOver?: () => void;
  onDragLeave?: () => void;
  onDragEnd?: () => void;
  onDrop?: (e: React.DragEvent) => void;
  disabled?: boolean;
}) => {
  const {
    icon,
    name,
    size,
    createdDateTime,
    selected,
    isShowCheck,
    onClick,
    onDoubleClick,
    onRightClick,
    onNameChange,
    onDragStart,
    onDrop,
    onDragLeave,
    onDragOver,
    disabled,
  } = props;

  const [isNameChange, setIsNameChange] = useState(false);
  const [editName, setEditName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickHandler = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    closeContextMenu();
    if (onClick) {
      onClick(e);
    }
  };

  const onDoubleClickHandler = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    closeContextMenu();

    if (onDoubleClick) {
      onDoubleClick(e);
    }
  };

  const onRightClickHandler = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (onRightClick) {
      onRightClick(
        e,
        () => {
          setIsNameChange(true);
          closeContextMenu();
        },
        () => {
          closeContextMenu();
        }
      );
    }
  };

  const changeName = () => {
    if (disabled) {
      return;
    }
    if (onNameChange) {
      onNameChange(editName);
    }
    setEditName("");
  };

  const onFocus = () => {
    if (disabled) {
      return;
    }
    setIsNameChange(true);
  };

  const onBlur = () => {
    if (disabled) {
      return;
    }
    if (editName !== "") {
      changeName();
    }
    setIsNameChange(false);
  };

  const onDropHandler = (e: React.DragEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    if (onDrop) {
      onDrop(e);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (disabled) {
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      if (editName !== "") {
        changeName();
      }
      setIsNameChange(false);
    }
  };

  useEffect(() => {
    if (isNameChange && inputRef && inputRef.current && !disabled) {
      inputRef.current.focus();
    }
  }, [disabled, isNameChange]);

  return (
    <StyledListViewItem
      selected={selected}
      onClick={onClickHandler}
      onDoubleClick={onDoubleClickHandler}
      onContextMenu={onRightClickHandler}
      draggable={true}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDropHandler}
    >
      <ListViewCell
        style={{
          flex: "1 1 auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <ListViewIcon src={icon} />
        {isNameChange ? (
          <ListViewNameInput
            ref={inputRef}
            placeholder={name}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            value={editName}
            onChange={(e) => {
              setEditName(e.currentTarget.value);
            }}
            onBlur={onBlur}
          />
        ) : (
          <ListViewName>{getEllipsisText(name.normalize(), 0, DEFAULT_SHOW_TEXT_LENGTH)}</ListViewName>
        )}
      </ListViewCell>
      <ListViewCell style={{ flex: "0 0 150px", paddingLeft: "20px", paddingRight: "20px" }}>
        <ListViewSize>{size && toFormattedSize(size)}</ListViewSize>
      </ListViewCell>
      <ListViewCell style={{ flex: "0 0 300px", paddingLeft: "20px", paddingRight: "20px" }}>
        <ListViewCreatedDateTime>{toFormattedLocaleDateAndTimeString(createdDateTime)}</ListViewCreatedDateTime>
      </ListViewCell>
      {isShowCheck && (
        <ListViewCell
          style={{
            flex: "0 0 30px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <CheckBox checked={selected} onChange={() => {}} />
        </ListViewCell>
      )}
    </StyledListViewItem>
  );
};

export const ListViewHeader = (props: {
  sort?: SORT;
  setSort: (sort?: SORT) => void;
  sortInverted: boolean;
  setSortInverted: (state: boolean) => void;
  isShowCheck: boolean;
}) => {
  const { sort, setSort, sortInverted, setSortInverted, isShowCheck } = props;

  const nameSorted = sort === SORT.NAME;
  const sizeSorted = sort === SORT.SIZE;
  const timeSorted = sort === SORT.TIME;

  const toggleNameSort = () => {
    if (nameSorted) {
      if (sortInverted) {
        setSort(undefined);
        setSortInverted(false);
      } else {
        setSortInverted(true);
      }
    } else {
      setSort(SORT.NAME);
      setSortInverted(false);
    }
  };

  const toggleSizeSort = () => {
    if (sizeSorted) {
      if (sortInverted) {
        setSort(undefined);
        setSortInverted(false);
      } else {
        setSortInverted(true);
      }
    } else {
      setSort(SORT.SIZE);
      setSortInverted(false);
    }
  };

  const toggleTimeSort = () => {
    if (timeSorted) {
      if (sortInverted) {
        setSort(undefined);
        setSortInverted(false);
      } else {
        setSortInverted(true);
      }
    } else {
      setSort(SORT.TIME);
      setSortInverted(false);
    }
  };

  return (
    <StyledListViewItem selected={false}>
      <ListViewCell
        style={{
          flex: "1 1 auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          marginLeft: "13px",
        }}
        onClick={toggleNameSort}
      >
        <ListViewName>파일명</ListViewName>
        {nameSorted && sortInverted ? (
          <SortedIcon src={DownArrowIcon} style={{ transform: "rotate(180deg)" }} />
        ) : nameSorted ? (
          <SortedIcon src={DownArrowIcon} />
        ) : undefined}
      </ListViewCell>
      <ListViewCell onClick={toggleSizeSort} style={{ flex: "0 0 150px", paddingLeft: "20px", paddingRight: "20px" }}>
        <ListViewSize>파일 크기</ListViewSize>
        {sizeSorted && sortInverted ? (
          <SortedIcon src={DownArrowIcon} style={{ transform: "rotate(180deg)" }} />
        ) : sizeSorted ? (
          <SortedIcon src={DownArrowIcon} />
        ) : undefined}
      </ListViewCell>
      <ListViewCell onClick={toggleTimeSort} style={{ flex: "0 0 300px", paddingLeft: "20px", paddingRight: "20px" }}>
        <ListViewCreatedDateTime>추가된 날짜</ListViewCreatedDateTime>
        {timeSorted && sortInverted ? (
          <SortedIcon src={DownArrowIcon} style={{ transform: "rotate(180deg)" }} />
        ) : timeSorted ? (
          <SortedIcon src={DownArrowIcon} />
        ) : undefined}
      </ListViewCell>

      {isShowCheck && (
        <ListViewCell
          style={{
            flex: "0 0 30px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        />
      )}
    </StyledListViewItem>
  );
};

const SortedIcon = styled.img``;

const ListViewIcon = styled.img`
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  margin-right: 11px;
  margin-left: 13px;
`;

const ListViewCell = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
`;

const ListViewNameInput = styled.input`
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #72757b;
`;

const ListViewName = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #72757b;
`;

const ListViewSize = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #72757b;
`;

const ListViewCreatedDateTime = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #72757b;
`;

const StyledListViewItem = styled.div<{ selected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.selected ? "#e8ecff" : "#FFFFFF")};
`;

export default ListViewItem;
