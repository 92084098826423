import SplitVideoDriver from "../Drivers/SplitVideo";

export interface ISplitRequestInfo {
  fileId: string;
  options: [
    {
      range: {
        startInMilliseconds: number;
        endInMilliseconds: number;
      };
      outputFileS3Path?: string;
    }
  ];
}

export interface ISplitResponseInfo {
  requestId: number;
  type: "SPLIT";
  outputDefinition: [
    {
      range: {
        startInMilliseconds: number;
        endInMilliseconds: number;
      };
      outputFileS3Path: string;
      outputFileS3Url: string;
    }
  ];
  inputS3FilePathParams: Array<{
    fileName: string;
    s3Path: string;
    base64EncodedFileName: string;
  }>;
  outputS3FilePathParams: Array<{
    fileName: string;
    s3Path: string;
    base64EncodedFileName: string;
  }>;
  createDateTime: string;
}

export interface ISplitDoneResponseInfo {
  status: string;
  result: Array<{
    range: {
      startInMilliseconds: number;
      endInMilliseconds: number;
    };
    outputFileS3Path: string;
    outputFileS3Url: string;
  }>;
}

export interface ISplitVideoDriver {
  requestSplit: (splitRequestInfo: ISplitRequestInfo) => Promise<ISplitRequestInfo>;
  getSplit: (requestId: string) => Promise<ISplitRequestInfo>;
  downloadSplit: (requestId: string) => Promise<string>;
}

export interface ISplitVideoUseCase {
  requestSplit: (
    fileId: string,
    ranges: { startTimeInMillSeconds: number; endTimeInMillSeconds: number },
    handlers?: ISplitVideoHandlers
  ) => Promise<ISplitResponseInfo>;
  getSplit: (requestId: string) => Promise<ISplitRequestInfo>;
}

export interface ISplitVideoHandlers {
  onRequest?: (response: ISplitResponseInfo) => void;
  onProgress?: () => void;
  onDone?: (response: ISplitDoneResponseInfo) => void;
}

class SplitVideoUseCase implements ISplitVideoUseCase {
  private splitVideoDriver;
  constructor() {
    this.splitVideoDriver = new SplitVideoDriver();
  }

  public requestSplit = async (
    fileId: string,
    ranges: {
      startTimeInMillSeconds: number;
      endTimeInMillSeconds: number;
    },
    handlers?: ISplitVideoHandlers
  ) => {
    const response = await this.splitVideoDriver.requestSplit({
      fileId,
      options: [
        {
          range: {
            startInMilliseconds: ranges.startTimeInMillSeconds,
            endInMilliseconds: ranges.endTimeInMillSeconds,
          },
        },
      ],
    });

    if (handlers?.onRequest) {
      handlers.onRequest(response);
    }

    let isDone = false;
    let doneResult: any;
    let error: any;

    const checkStatus = () => {
      if (isDone) {
        if (handlers?.onDone) {
          handlers.onDone(doneResult);
        }
        return;
      }

      if (error != null) {
        return;
      }

      this.getSplit(response.requestId).then((response) => {
        if (response.status === "DONE") {
          doneResult = response;
          isDone = true;
        }

        if (response.status === "ERROR") {
          error = response;
        }

        setTimeout(() => {
          checkStatus();
        }, 5000);
      });
    };

    checkStatus();

    return response;
  };

  public getSplit = async (requestId: string) => {
    return await this.splitVideoDriver.getSplit(requestId);
  };
}

export default SplitVideoUseCase;
