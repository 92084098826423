import * as React from "react";
import { useCallback, useLayoutEffect } from "react";

interface IProps {
  scrollX: number;
  pxPerSecond: number;
  duration: number;
  wave: number[];
  pps: number;
  onSeek(time: number): void;
}

const CANVAS_MAX_WIDTH = 4000;
const WaveV3Component: React.FunctionComponent<IProps> = (props) => {
  const { scrollX, pxPerSecond, duration, wave, pps, onSeek: seek } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const width = React.useMemo(() => duration * pxPerSecond, [duration, pxPerSecond]);
  const { clientHeight: height = 0 } = useElementSize(containerRef);
  // Draw canvas
  const data = React.useMemo(() => {
    if (height < 1) {
      return [];
    }
    const defaultY = height / 2;
    const maxHeight = wave.reduce((a, b) => Math.max(a, Math.abs(b)));
    const canvasData: IWaveData[] = [];
    wave.forEach((it, idx) => {
      // Odd: negative, Event: positive
      const x = (Math.floor(idx / 2) / pps) * pxPerSecond;
      const canvasIdx = Math.floor(x / CANVAS_MAX_WIDTH);
      if (!canvasData[canvasIdx]) {
        canvasData[canvasIdx] = { positive: [], negative: [] };
      }
      canvasData[canvasIdx][idx % 2 === 0 ? "positive" : "negative"].push({
        x: x % CANVAS_MAX_WIDTH,
        y: (1 + it / maxHeight) * defaultY,
      });
    });
    return canvasData;
  }, [height, wave, pps, pxPerSecond]);
  const [startIdx, endIdx] = React.useMemo(() => {
    const currentIdx = data.findIndex((_, idx, arr) => {
      const currentStart = CANVAS_MAX_WIDTH * idx;
      const currentEnd = currentStart + (arr.length - 1 === idx ? width % CANVAS_MAX_WIDTH : CANVAS_MAX_WIDTH);
      return scrollX >= currentStart && scrollX <= currentEnd;
    });
    return [currentIdx <= 0 ? 0 : currentIdx - 1, currentIdx >= data.length - 1 ? currentIdx : currentIdx + 1];
  }, [data, scrollX, width]);
  const onSeek: React.MouseEventHandler<HTMLCanvasElement> = React.useCallback(
    (e) => {
      if (!e.target) {
        return;
      }
      const time = ((e.currentTarget.offsetLeft + e.nativeEvent.offsetX) / pxPerSecond) * 1000;
      seek(time);
    },
    [pxPerSecond, seek]
  );

  return React.useMemo(
    () => (
      <div className={"wavev3component"} ref={containerRef} style={{ position: "relative" }}>
        {data.slice(startIdx, endIdx + 1).map((it, idx) => {
          const realIdx = startIdx + idx;
          return (
            <WaveCanvas
              key={realIdx}
              width={data.length - 1 === realIdx ? width % CANVAS_MAX_WIDTH : CANVAS_MAX_WIDTH}
              height={height}
              style={{ position: "absolute", left: realIdx * CANVAS_MAX_WIDTH }}
              data={it}
              onClick={onSeek}
            />
          );
        })}
      </div>
    ),
    [data, startIdx, endIdx, width, height, onSeek]
  );
};
export default WaveV3Component;

export interface ICanvasData {
  x: number;
  y: number;
}
export interface IWaveData {
  positive: ICanvasData[];
  negative: ICanvasData[];
}
interface ILineStyle {
  fill?: string;
  width?: number;
  globalAlpha?: number;
}
async function drawWave(context: CanvasRenderingContext2D, data: IWaveData, style: ILineStyle) {
  const { positive, negative } = data;
  const { clientWidth, clientHeight } = context.canvas;
  const defaultY = clientHeight / 2;
  context.beginPath();
  context.moveTo(0, defaultY);
  for (const { x, y } of positive) {
    context.lineTo(x, y);
  }
  const lastPositive = positive[positive.length - 1];
  context.lineTo(clientWidth, lastPositive.y);
  const lastNegative = negative[negative.length - 1];
  context.lineTo(clientWidth, lastNegative.y);
  const { length } = negative;
  for (let idx = length - 1; idx > 0; idx--) {
    const { x, y } = negative[idx];
    context.lineTo(x, y);
  }
  const firstNegative = negative[0];
  context.lineTo(0, firstNegative.y);
  context.lineTo(0, defaultY);
  const { width: lineWidth = 1, fill = "#dcdde3", globalAlpha = 0.59 } = style;
  context.lineWidth = lineWidth;
  context.fillStyle = fill;
  context.globalAlpha = globalAlpha;
  context.fill();
}
interface IWaveCanvasProps {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  lineStyle?: ILineStyle;
  data: IWaveData;
  onClick: React.MouseEventHandler<HTMLCanvasElement>;
}
const WaveCanvas: React.FunctionComponent<IWaveCanvasProps> = (props) => {
  const { width, height, style, lineStyle = {}, data, onClick } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  React.useEffect(() => {
    const { positive, negative } = data;
    if (!canvasRef.current || !positive.length || !negative.length) {
      return;
    }
    const context = canvasRef.current.getContext("2d");
    if (!context) {
      return;
    }
    drawWave(context, data, lineStyle).catch((e) => console.warn("WaveCanvas: Something wrong", e));
    return () => {
      const { clientWidth, clientHeight } = context.canvas;
      context.clearRect(0, 0, clientWidth, clientHeight);
    };
  }, [lineStyle, data]);
  return React.useMemo(
    () => (
      <canvas ref={canvasRef} className={"wavecanvas"} width={width} height={height} style={style} onClick={onClick} />
    ),
    [width, height, style, onClick]
  );
};

interface IElementSize {
  clientWidth?: number;
  clientHeight?: number;
  offsetWidth?: number;
  offsetHeight?: number;
  scrollWidth?: number;
  scrollHeight?: number;
}
export function useElementSize<E extends HTMLElement>(ref?: React.RefObject<E>) {
  const [size, setSize] = React.useState<IElementSize>({});

  const updateSize = useCallback((ref: React.RefObject<E>) => {
    const { current = {} } = ref || {};
    const { clientWidth, clientHeight, offsetWidth, offsetHeight, scrollWidth, scrollHeight } = (current || {}) as E;
    setSize({ clientHeight, clientWidth, offsetWidth, offsetHeight, scrollWidth, scrollHeight });
  }, []);

  React.useLayoutEffect(() => {
    if (ref) {
      updateSize(ref);
    }
  }, [ref, updateSize]);

  useLayoutEffect(() => {
    const callback = () => {
      if (ref) {
        updateSize(ref);
      }
    };
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, [ref, updateSize]);

  return size;
}
