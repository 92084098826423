import TextSourceRepository, {
  ISubtitle,
  ITextSourceRepository,
  ITextSourceResponse,
} from "../Repositories/TextSource";
import { ITextScript, ITextSource } from "../../Interfaces";
import TextSourceService, { TextScriptExportType, ITextSourceService } from "../Services/TextSource";

export interface ITextSourceUseCase {
  getTextSource: (key: string, fileId: string) => Promise<ITextSource | null>;
}

class TextSourceUseCase implements ITextSourceUseCase {
  private textSourceRepository: ITextSourceRepository;
  private textSourceService: ITextSourceService;
  constructor() {
    this.textSourceRepository = new TextSourceRepository();
    this.textSourceService = new TextSourceService();
  }

  public getTextSource = async (key: string, fileId: string) => {
    const asrResponse = await this.textSourceRepository.getASRText(fileId);

    if (!asrResponse.done) {
      return null;
    }

    return convertToTextSource(key, asrResponse);
  };

  public exportTextSource = (type: TextScriptExportType, textSource: ITextSource) => {
    if (type === TextScriptExportType.TXT) {
      return this.textSourceService.convert2TXT(textSource);
    }

    if (type === TextScriptExportType.SRT) {
      return this.textSourceService.convert2SRT(textSource);
    }

    if (type === TextScriptExportType.SMI) {
      return this.textSourceService.convert2SMI(textSource);
    }

    throw new Error("not support export type");
  };
}

export default TextSourceUseCase;

const convertToTextSource = (key: string, asrResponse: ITextSourceResponse): ITextSource => {
  return {
    key,
    type: "TEXT",
    data: {
      durationInMilliSeconds: asrResponse.response.duration.total_milliseconds,
      scripts: convertToTextScripts(asrResponse.response.subtitles),
    },
  };
};

const convertToTextScripts = (subtitle: ISubtitle[]): ITextScript[] => {
  return subtitle.map((s) => ({
    startTime: s.start.total_milliseconds,
    endTime: s.end.total_milliseconds,
    type: "TEXT_SCRIPT",
    data: {
      language: "",
      text: s.text,
    },
  }));
};
