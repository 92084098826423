import { FileUseCases } from "../../../UseCases/Files/File";
import { IFileUseCase } from "../../../UseCases/Interfaces";
import { IVideoSource } from "../../Interfaces";

export interface IVideoSourceUseCase {
  getVideoSource: (fileId: string) => Promise<IVideoSource>;
}

class VideoSourceUseCase implements IVideoSourceUseCase {
  private fileUseCase: IFileUseCase;
  constructor() {
    this.fileUseCase = new FileUseCases();
  }
  public getVideoSource = async (fileId: string) => {
    const file = await this.fileUseCase.getFile(fileId);
    const videoSource: IVideoSource = {
      type: "VIDEO",
      key: fileId,
      data: {
        fileId,
        name: file.name,
        src: file.s3Url!,
      },
    };
    return videoSource;
  };
}

export default VideoSourceUseCase;
