// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideBarItem from "./SideBarItem";

import MyAccountIcon from "../../../../assets/images/default-profile.svg";
import SearchIcon from "../../../../assets/images/1-icon-search-20-px.svg";
import FolderIcon from "../../../../assets/images/1-icon-folder-open-20-px.svg";
import { AuthenticationContext } from "../../../Contexts/AuthenticationContext";
import { PopupContext } from "../../../Contexts/PopupContext";
import LoggedInPopup from "../Authentication/LoggedInPopup";

const TopSideBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { isLogin } = useContext(AuthenticationContext);
  const { showPopup } = useContext(PopupContext);
  const [toggleAuthPopup, setToggleAuthPopup] = useState(false);

  const navigations = [
    {
      icon: MyAccountIcon,
      text: <>내 계정</>,
      path: "/authentication",
      onClick: () => {
        if (isLogin && showPopup) {
          setToggleAuthPopup(true);
          showPopup(
            <LoggedInPopup
              onClose={() => {
                setToggleAuthPopup(false);
              }}
            />
          );
        } else {
          history.push("/authentication");
        }
      },
      toggled: isLogin ? toggleAuthPopup : location.pathname.split("/")[1] === "authentication",
    },
    {
      icon: SearchIcon,
      text: <>검색</>,
      path: "/search",
      onClick: () => {
        history.push("/search");
      },
      toggled: !toggleAuthPopup && location.pathname.split("/")[1] === "search",
    },
    {
      icon: FolderIcon,
      text: <>내 아카이브</>,
      path: "/directory",
      onClick: () => {
        history.push("/directory");
      },
      toggled: !toggleAuthPopup && location.pathname.split("/")[1] === "directory",
    },
  ];

  return (
    <>
      {navigations.map(({ icon, onClick, text, path, toggled }, index) => (
        <SideBarItem key={index} toggled={toggled} icon={icon} text={text} onClick={onClick} />
      ))}
    </>
  );
};

export default TopSideBar;
