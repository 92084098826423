import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toFormattedSize } from "../../../../../utils/utils";
import { closeContextMenu } from "../../../GlobalContextMenu";
import { getEllipsisText } from "../Utils";

export const DEFAULT_SHOW_TEXT_LENGTH = 31;

const GridViewItem = (props: {
  icon: string;
  name: string;
  size: number;
  createdDateTime: string;
  selected: boolean;
  isShowCheck: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
  onRightClick?: (e: React.MouseEvent, onNameChange: () => void) => void;
  onNameChange: (name: string) => void;
  onDragStart?: () => void;
  onDragOver?: () => void;
  onDragLeave?: () => void;
  onDragEnd?: () => void;
  onDrop?: (e: React.DragEvent) => void;
  disabled?: boolean;
}) => {
  const {
    icon,
    name,
    size,
    selected,
    onClick,
    onDoubleClick,
    onRightClick,
    onNameChange,
    onDragOver,
    onDragLeave,
    onDragStart,
    onDragEnd,
    onDrop,
    disabled,
  } = props;

  const [isNameChange, setIsNameChange] = useState(false);
  const [editName, setEditName] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onClickHandler = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    closeContextMenu();
    if (onClick) {
      onClick(e);
    }
  };

  const onDoubleClickHandler = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    closeContextMenu();

    if (onDoubleClick) {
      onDoubleClick(e);
    }
  };

  const onRightClickHandler = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (onRightClick) {
      onRightClick(e, () => {
        setIsNameChange(true);
        closeContextMenu();
      });
    }
  };

  const onDropHandler = (e: React.DragEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    if (onDrop) {
      onDrop(e);
    }
  };

  const changeName = () => {
    if (disabled) {
      return;
    }
    if (editName !== name) {
      if (onNameChange) {
        onNameChange(editName);
      }
    }

    setEditName("");
  };

  const onFocus = () => {
    if (disabled) {
      return;
    }
    setIsNameChange(true);
  };

  const onBlur = () => {
    if (disabled) {
      return;
    }
    if (editName !== "") {
      changeName();
    }
    setIsNameChange(false);
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (disabled) {
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      if (editName !== "") {
        changeName();
      }
      setIsNameChange(false);
    }
  };

  useEffect(() => {
    if (isNameChange && inputRef && inputRef.current && !disabled) {
      inputRef.current.focus();
    }
  }, [disabled, isNameChange]);

  return (
    <StyledGridViewItem
      draggable={true}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDropHandler}
      onDragEnd={onDragEnd}
      selected={selected}
      onClick={onClickHandler}
      onDoubleClick={onDoubleClickHandler}
      onContextMenu={onRightClickHandler}
    >
      <GridViewIcon src={icon} />
      {isNameChange ? (
        <GridViewNameInput
          ref={inputRef}
          placeholder={name}
          onFocus={onFocus}
          value={editName}
          onChange={(e) => {
            setEditName(e.currentTarget.value);
          }}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      ) : (
        <GridViewName>{getEllipsisText(name.normalize(), 0, DEFAULT_SHOW_TEXT_LENGTH)}</GridViewName>
      )}
      {!isNameChange && <GridViewSize>{size && toFormattedSize(size)}</GridViewSize>}
    </StyledGridViewItem>
  );
};

const GridViewIcon = styled.img`
  width: 146px;
  min-width: 146px;
  min-height: 146px;
  height: 146px;
`;

const GridViewNameInput = styled.textarea`
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #72757b;
  resize: none;
  display: flex;
  align-items: center;
`;

const GridViewName = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-break: break-all;
  color: #33343d;
`;

const GridViewSize = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #657eff;
`;

const StyledGridViewItem = styled.div<{ selected: boolean }>`
  padding: 14px 17px 30px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => (props.selected ? "#e8ecff" : "#ffffff")};
  flex: 0 0 228px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${GridViewIcon} {
    margin-bottom: 11px;
  }

  ${GridViewName} {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: ${(props) => (props.selected ? "#e8ecff" : "#f2f4ff")};
  }
`;

export default GridViewItem;
