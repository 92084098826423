import AudioSourceRepository, { IAudioSourceRepository } from "../Repositories/AudioSource";
import { IAudioSource } from "../../Interfaces";

export interface IAudioSourceUseCase {
  getAudioSource: (fileId: string) => Promise<IAudioSource | null>;
}

class AudioSourceUseCase implements IAudioSourceUseCase {
  private audioSourceRepository: IAudioSourceRepository;
  constructor() {
    this.audioSourceRepository = new AudioSourceRepository();
  }

  public getAudioSource = async (fileId: string) => {
    const audioWaveSource = await this.audioSourceRepository.getAudioSource(fileId);

    if (audioWaveSource.status !== "DONE") {
      return null;
    }

    const audioSource: IAudioSource = {
      key: "",
      type: "AUDIO",
      data: {
        pps: audioWaveSource.pps!,
        wave: audioWaveSource.wave!,
      },
    };

    return audioSource;
  };
}

export default AudioSourceUseCase;
