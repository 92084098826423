import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { ISelection } from "../../../../../Programs/VideoEditor/Controllers/VideoEditor";
import styled from "styled-components";
import * as R from "ramda";
import { ITextScript, ITextSource } from "../../../../../Programs/Interfaces";

const TextTimeline = React.memo(
  (props: {
    selection: ISelection | null;
    parentWidth: number;
    duration: number;
    textSource?: ITextSource;
    height: number;
    scrollX: number;
    unitWidth: number;
    windowWidth: number;
    subscribeTime: (f: (timeInMilliSeconds: number) => void) => { unsubscribe: () => void } | undefined;
  }) => {
    const {
      selection,
      textSource,
      parentWidth,
      height,
      duration,
      subscribeTime,
      unitWidth,
      scrollX,
      windowWidth,
    } = props;

    const [focusScript, setFocusScript] = useState<ITextScript>();

    useEffect(() => {
      const subscription = subscribeTime((timeInMilliSeconds: number) => {
        if (textSource == null) {
          return;
        }

        const targetScript = textSource.data.scripts
          .filter((script) => script.startTime <= timeInMilliSeconds && script.endTime >= timeInMilliSeconds)
          .pop();

        if (!R.equals(targetScript, focusScript)) {
          setFocusScript(targetScript);
        }
      });

      return subscription?.unsubscribe;
    }, [subscribeTime, textSource, focusScript]);

    const textScripts = textSource?.data.scripts;

    const [startIdx, endIdx] = React.useMemo(() => {
      const startTime = Math.floor(scrollX / unitWidth) < 0 ? 0 : Math.floor(scrollX / unitWidth);
      const endTime = Math.ceil((scrollX + windowWidth + windowWidth / 2) / unitWidth);

      const startIdx =
        textScripts == null
          ? 0
          : textScripts.findIndex(
              (script) => script.startTime >= startTime * 1000 || script.endTime >= startTime * 1000
            );

      const endIdx =
        textScripts == null
          ? 0
          : textScripts.length - [...textScripts].reverse().findIndex((script) => script.endTime <= endTime * 1000);

      return [startIdx, endIdx];
    }, [scrollX, textScripts, unitWidth, windowWidth]);

    if (parentWidth == null || duration == null) {
      return null;
    }

    const isInSelection = (textScript: ITextScript) => {
      if (selection == null || selection.startTimeInMillSeconds == null || selection.endTimeInMillSeconds == null) {
        return false;
      }
      return (
        textScript.startTime >= selection?.startTimeInMillSeconds &&
        textScript.endTime <= selection.endTimeInMillSeconds
      );
    };

    return (
      <StyledTextTimeline height={height}>
        {textScripts &&
          textScripts
            .slice(startIdx, endIdx + 1)
            .map((textScript) => (
              <TextTimelineBox
                key={textScript.startTime}
                text={textScript.data.text}
                focused={selection != null ? isInSelection(textScript) : R.equals(textScript, focusScript)}
                width={((textScript.endTime - textScript.startTime) * parentWidth) / duration}
                left={(textScript.startTime * parentWidth) / duration}
              />
            ))}
      </StyledTextTimeline>
    );
  }
);

const TextTimelineBox = (props: { text: string; focused: boolean; width: number; left: number }) => {
  const { focused, width, left, text } = props;

  const textRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledTextTimelineBox focused={focused} width={width} left={left} ref={textRef}>
      <StyledText>{text}</StyledText>
    </StyledTextTimelineBox>
  );
};

export default TextTimeline;

const StyledTextTimelineBox = styled.div<{
  focused: boolean;
  width: number;
  left: number;
}>`
  position: absolute;
  left: ${(props) => props.left}px;
  padding: 6px 7px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px ${(props) => (props.focused ? "#cad0f5" : "#edeef4")};
  background-color: ${(props) => (props.focused ? "#e8ecff" : "#f7f7f7")};

  width: ${(props) => props.width}px;
  height: 100px;
`;

const StyledText = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.2px;
  color: #33343d;
  word-break: break-all;
  width: 100%;
`;

const StyledTextTimeline = styled.div<{ height: number }>`
  position: relative;
  height: ${(props) => props.height}px;
  padding-bottom: 11px;
`;
